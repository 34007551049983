"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTransfersQueryParamsSchema = exports.cancelTransferResponseSchema = exports.cancelTransferSuccessResponseSchema = exports.cancelTransferErrorResponseSchema = exports.createTransferResponse = exports.createTransferFromVirtualBinResponseSchema = exports.createTransferFromVirtualBinRequestSchema = exports.createTransferRequest = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
const generic_1 = require("../commands/outcomes/generic");
const transfer_1 = require("../commands/outcomes/transfer");
const base_1 = require("../events/base");
const transfer_2 = require("../events/transfer");
const model_1 = require("../model");
const fulfilmentOrder_1 = require("./fulfilmentOrder");
const createTransferRequestItem = model_1.retailUnitSchema
    .pick({
    barcode: true,
    externalId: true,
    depthMm: true,
    heightMm: true,
    imageSrc: true,
    name: true,
    options: true,
    sku: true,
    weightGrams: true,
    widthMm: true,
})
    .extend({ quantity: zod_http_schemas_1.z.number() });
exports.createTransferRequest = zod_http_schemas_1.z.object({
    notes: zod_http_schemas_1.z.string().optional(),
    isTest: zod_http_schemas_1.z.boolean().optional(),
    shipment: zod_http_schemas_1.z.object({
        eta: zod_http_schemas_1.z.union([zod_http_schemas_1.z.string(), zod_http_schemas_1.z.date()]),
        trackingNumber: zod_http_schemas_1.z.string(),
        carrier: zod_http_schemas_1.z.string(),
    }),
    senderName: zod_http_schemas_1.z.string(),
    externalId: zod_http_schemas_1.z.union([base_1.StringId, zod_http_schemas_1.z.number()]),
    transferNumberRef: zod_http_schemas_1.z.string(),
    parcelType: zod_http_schemas_1.z.string(),
    address: fulfilmentOrder_1.PostalAddressSchema,
    merchantId: zod_http_schemas_1.z.string(),
    items: createTransferRequestItem.array(),
});
exports.createTransferFromVirtualBinRequestSchema = zod_http_schemas_1.z.object({
    organisationId: base_1.StringId,
    notes: zod_http_schemas_1.z.string().optional(),
    shipment: zod_http_schemas_1.z.object({
        eta: zod_http_schemas_1.z.union([zod_http_schemas_1.z.string(), zod_http_schemas_1.z.date()]),
        trackingNumber: zod_http_schemas_1.z.string(),
        carrier: zod_http_schemas_1.z.string(),
    }),
    items: zod_http_schemas_1.z
        .object({ barcode: base_1.StringId, quantity: zod_http_schemas_1.z.number().int().positive() })
        .array(),
    parcelType: zod_http_schemas_1.z.string(),
    address: fulfilmentOrder_1.PostalAddressSchema,
    totalWeightKg: zod_http_schemas_1.z.number(),
});
exports.createTransferFromVirtualBinResponseSchema = zod_http_schemas_1.z.discriminatedUnion('outcome', [
    generic_1.Success,
    zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('ORGANISATION_HAS_NO_VIRTUAL_BIN') }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('INSUFFICIENT_STOCK_IN_BIN'),
        barcodesWithInsufficientStock: zod_http_schemas_1.z
            .object({
            barcode: base_1.StringId,
            quantityMissing: zod_http_schemas_1.z.number().int().positive(),
        })
            .array(),
    }),
    transfer_1.LabelGenerationFailed,
    transfer_1.SaveLabelFailed,
]);
const createTransferResponseErrorOutcomes = zod_http_schemas_1.z.union([
    transfer_1.NoItemsFoundForTransfer,
    transfer_1.NoTransferNumberRefFoundForTransfer,
    transfer_1.TransferTotalWeightMustBePositive,
    transfer_1.NoWarehouseFound,
    transfer_1.LabelGenerationFailed,
    transfer_1.SaveLabelFailed,
    transfer_1.ItemsHaveInvalidWeight,
    transfer_1.TransferAlreadyExists,
]);
const createTransferResponseSuccessOutcomes = transfer_1.CreateTransferSuccess;
exports.createTransferResponse = zod_http_schemas_1.z.union([
    createTransferResponseErrorOutcomes,
    createTransferResponseSuccessOutcomes,
]);
exports.cancelTransferErrorResponseSchema = zod_http_schemas_1.z.discriminatedUnion('outcome', [
    zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('NO_TRANSFER_FOUND') }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('INVALID_TRANSFER_STATUS'),
        expectedStatuses: model_1.transferSchema.shape.state.array(),
        currentStatus: model_1.transferSchema.shape.state,
    }),
]);
exports.cancelTransferSuccessResponseSchema = generic_1.Success;
exports.cancelTransferResponseSchema = zod_http_schemas_1.z.union([
    exports.cancelTransferErrorResponseSchema,
    exports.cancelTransferSuccessResponseSchema,
]);
exports.getTransfersQueryParamsSchema = zod_http_schemas_1.z.object({
    originType: transfer_2.transferOriginTypeSchema.optional(),
});
