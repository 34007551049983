"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.hydratedTransferSchema = exports.transferSchema = exports.hydratedTransferItemSchema = exports.TransferItem = exports.TransferState = void 0;
const z = __importStar(require("zod"));
const base_1 = require("../events/base");
const transfer_1 = require("../events/transfer");
const merchant_1 = require("./merchant");
const retailUnit_1 = require("./retailUnit");
exports.TransferState = z.enum([
    'AWAITING_PICKUP',
    'IN_TRANSIT',
    'AWAITING_PUTAWAY',
    'PUTAWAY_IN_PROGRESS',
    'COMPLETED',
    'CANCELLED',
]);
exports.TransferItem = z.object({
    quantity: z.number().int().nonnegative(),
    quantityPutaway: z.number().int().nonnegative().optional(),
    retailUnitKey: base_1.StringId,
    externalTransferItemId: z.union([z.string(), z.number()]).optional(),
});
exports.hydratedTransferItemSchema = exports.TransferItem.extend({
    retailUnit: retailUnit_1.retailUnitSchema.optional(),
});
exports.transferSchema = z.object({
    id: z.string().min(1),
    origin: transfer_1.transferOriginSchema,
    destination: transfer_1.transferDestinationSchema,
    state: exports.TransferState,
    items: z.array(exports.TransferItem),
    notes: z.string().optional(),
    isTest: z.boolean().optional(),
    shipment: z
        .object({
        eta: z.union([z.string(), z.date()]),
        trackingNumber: z.string(),
        carrier: z.string(),
    })
        // TODO(WMS-735): address orphaned todo
        // eslint-disable-next-line todo-plz/ticket-ref
        // TODO: ideally this would be required, but for versioning sake it isn't
        .optional(),
    labelPath: base_1.LabelPaths.optional(),
    hasCompletedInventoryAdjustments: z.boolean().optional(),
    putawayMode: merchant_1.putawayModeSchema.optional(),
    organisationId: z.string().min(1),
    activeOnPorts: z.number().int().positive().array().optional(),
});
exports.hydratedTransferSchema = exports.transferSchema
    .omit({
    items: true,
})
    .extend({ items: exports.hydratedTransferItemSchema.array() });
