import { ErrorBody } from 'api-schema';
import { apiHttpClient } from 'api-schema/lib/schema';

const host = window.location.host;
const isLocal = host.includes('localhost');

export const baseURL = isLocal
  ? `http://localhost:${process.env.REACT_APP_API_PORT || '8080'}/api`
  : '/api';

export type ApiClient = ReturnType<typeof apiHttpClient>;
export const getApiClient = (token?: string): ApiClient =>
  apiHttpClient(baseURL, token);

export const isNotErrorResponse = <T>(
  response: T | ErrorBody,
  status: number
): response is T => {
  return status < 400 && (response as ErrorBody).error === undefined;
};
