"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageEvent = exports.PlaceOrRemoveItemsInPartitionFailed = exports.putawayItemsRemovedFromPartitionSchema = exports.putawayItemsPlacedInPartitionSchema = exports.stockRemovedFromVirtualBinSchema = exports.stockAddedToVirtualBinSchema = exports.virtualBinPartitionUpdateSchema = exports.stockMovedToPartitionFromVirtualBinSchema = exports.partitionsMovedToVirtualBinSchema = exports.autostoreBinUnlockedSchema = exports.autostoreBinLockedSchema = exports.addStockToEmptyPartitionFailedSchema = exports.stockAddedToEmptyPartitionSchema = exports.StockInBinAdjusted = exports.cmcGenesysToteSentToManualPackSchema = exports.sendCmcGenesysToteToManualPackFailedSchema = exports.cmcGenesysToteExitedSuccessfullySchema = exports.totesEmptiedSchema = exports.storageUnitsStatusChangedSchema = exports.toteStorageCreatedSchema = exports.autostoreBinsInductedSchema = exports.autostoreBinsCreatedSchema = void 0;
const zod_1 = require("zod");
const model_1 = require("../model");
const base_1 = require("./base");
exports.autostoreBinsCreatedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_BINS_CREATED'),
    payload: zod_1.z.object({
        autostoreBins: zod_1.z
            .object({
            id: zod_1.z.string(),
            binType: zod_1.z.union([zod_1.z.literal(1), zod_1.z.literal(2), zod_1.z.literal(5)]),
            partitions: zod_1.z.array(zod_1.z.never()),
            autostoreBinId: zod_1.z.number().int().gte(10000),
        })
            .array(),
    }),
});
const BinIdType = zod_1.z.string();
const AutostoreBinIdType = zod_1.z.number();
exports.autostoreBinsInductedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_BINS_INDUCTED'),
    payload: zod_1.z.object({
        binInfos: zod_1.z.array(zod_1.z.tuple([BinIdType, AutostoreBinIdType])),
    }),
});
exports.toteStorageCreatedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TOTE_STORAGE_CREATED'),
    payload: zod_1.z.object({
        totes: zod_1.z.object({ id: zod_1.z.string() }).array(),
    }),
});
exports.storageUnitsStatusChangedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('STORAGE_UNITS_STATUS_CHANGED'),
    payload: zod_1.z.object({
        storageUnitIds: base_1.StringId.array(),
        status: model_1.StorageStatus,
    }),
});
exports.totesEmptiedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TOTES_EMPTIED'),
    payload: zod_1.z.object({
        toteIds: zod_1.z.array(base_1.StringId),
    }),
});
exports.cmcGenesysToteExitedSuccessfullySchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_TOTE_EXITED_SUCCESSFULLY'),
    payload: zod_1.z.object({
        toteId: base_1.StringId,
        referenceId: zod_1.z.string(),
    }),
});
const legacySendToManualPackFailedReasonSchema = zod_1.z.union([
    zod_1.z.literal('TOTE_NOT_FOUND'),
    zod_1.z.literal('TOTE_NOT_ON_CONVEYOR'),
    zod_1.z.literal('UNABLE_TO_GET_CONVEYOR_TOTE_STATE'),
    zod_1.z.literal('NO_FULFILMENT_ORDER'),
    zod_1.z.literal('INVALID_FULFILMENT_ORDER_STATUS'),
    zod_1.z.literal('NO_JOB_ASSIGNED_TO_TOTE'),
    zod_1.z.literal('UNABLE_TO_UPDATE_TOTE_JOB'),
]);
exports.sendCmcGenesysToteToManualPackFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('SEND_CMC_GENESYS_TOTE_TO_MANUAL_PACK_FAILED'),
    payload: zod_1.z.object({
        reason: legacySendToManualPackFailedReasonSchema.or(zod_1.z.discriminatedUnion('outcome', [
            zod_1.z.object({
                outcome: zod_1.z.literal('TOTE_NOT_FOUND'),
                toteId: zod_1.z.string().min(1),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('TOTE_NOT_ON_CONVEYOR'),
                toteId: zod_1.z.string().min(1),
                fulfilmentOrderId: zod_1.z.string().min(1),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('UNABLE_TO_GET_CONVEYOR_TOTE_STATE'),
                toteId: zod_1.z.string().min(1),
                fulfilmentOrderId: zod_1.z.string().min(1),
                responseData: zod_1.z.union([
                    zod_1.z.object({
                        status: zod_1.z.number().optional(),
                        title: zod_1.z.string(),
                        details: zod_1.z.string(),
                    }),
                    zod_1.z.object({
                        toteId: zod_1.z.string(),
                        bayId: zod_1.z.string().nullable(),
                        jobId: zod_1.z.string().nullable(),
                        zoneRequests: zod_1.z.record(zod_1.z.boolean()),
                        zoneId: zod_1.z.string().nullable(),
                    }),
                ]),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('NO_FULFILMENT_ORDER'),
                toteId: zod_1.z.string().min(1),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('INVALID_FULFILMENT_ORDER_STATUS'),
                fulfilmentOrderId: zod_1.z.string().min(1),
                actualStatus: zod_1.z.string(),
                expectedStatuses: zod_1.z.string().array(),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('NO_JOB_ASSIGNED_TO_TOTE'),
                fulfilmentOrderId: zod_1.z.string().min(1),
                toteId: zod_1.z.string().min(1),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('UNABLE_TO_UPDATE_TOTE_JOB'),
                response: zod_1.z.object({
                    outcome: zod_1.z.string(),
                    data: zod_1.z.object({
                        status: zod_1.z.number().optional(),
                        title: zod_1.z.string(),
                        details: zod_1.z.string(),
                    }),
                }),
            }),
        ])),
        toteId: base_1.StringId,
        fulfilmentOrderId: base_1.StringId.optional(),
    }),
});
exports.cmcGenesysToteSentToManualPackSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_TOTE_SENT_TO_MANUAL_PACK'),
    payload: zod_1.z.object({
        toteId: base_1.StringId,
        fulfilmentOrderId: base_1.StringId,
    }),
});
exports.StockInBinAdjusted = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('STOCK_IN_BIN_ADJUSTED'),
    payload: zod_1.z.object({
        binId: zod_1.z.string().min(1),
        autostoreBinId: zod_1.z.number().positive(),
        partitionNumber: zod_1.z.number().positive(),
        retailUnit: zod_1.z.object({
            merchantId: zod_1.z.string().min(1),
            barcode: zod_1.z.string().min(1),
        }),
        previousQuantity: zod_1.z.number().nonnegative(),
        updatedQuantity: zod_1.z.number().nonnegative(),
    }),
});
exports.stockAddedToEmptyPartitionSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('STOCK_ADDED_TO_EMPTY_PARTITION'),
    payload: zod_1.z.object({
        binId: zod_1.z.string().min(1),
        autostoreBinId: zod_1.z.number().positive(),
        partitionNumber: zod_1.z.number().positive(),
        retailUnitKey: zod_1.z.string(),
        merchantId: zod_1.z.string(),
        barcode: zod_1.z.string(),
        quantity: zod_1.z.number().nonnegative(),
    }),
});
exports.addStockToEmptyPartitionFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('ADD_STOCK_TO_EMPTY_PARTITION_FAILED'),
    payload: zod_1.z.object({
        binId: zod_1.z.string().min(1),
        partitionNumber: zod_1.z.number().positive(),
        retailUnitKey: zod_1.z.string(),
        quantity: zod_1.z.number().nonnegative(),
        reason: zod_1.z.discriminatedUnion('outcome', [
            zod_1.z.object({ outcome: zod_1.z.literal('BIN_NOT_FOUND') }),
            zod_1.z.object({ outcome: zod_1.z.literal('PARTITION_NOT_FOUND') }),
            zod_1.z.object({ outcome: zod_1.z.literal('UNKNOWN_RETAIL_UNIT') }),
            zod_1.z.object({ outcome: zod_1.z.literal('PARTITION_NOT_EMPTY') }),
            zod_1.z.object({
                outcome: zod_1.z.literal('BIN_ALREADY_CONTAINS_RETAIL_UNIT'),
                partitionNumber: zod_1.z.number().positive(),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('INVALID_QUANTITY'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('BIN_NOT_USED_BY_MERCHANT'),
            }),
        ]),
    }),
});
exports.autostoreBinLockedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_BIN_LOCKED'),
    payload: zod_1.z.object({
        binId: base_1.StringId,
        autostoreBinId: zod_1.z.number(),
    }),
});
exports.autostoreBinUnlockedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_BIN_UNLOCKED'),
    payload: zod_1.z.object({
        binId: base_1.StringId,
        autostoreBinId: zod_1.z.number(),
    }),
});
exports.partitionsMovedToVirtualBinSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PARTITIONS_MOVED_TO_VIRTUAL_BIN'),
    payload: zod_1.z.object({
        merchantId: zod_1.z.string().min(1),
        fromBinId: zod_1.z.string().min(1),
        toVirtualBinId: zod_1.z.string().min(1),
        partitionsMoved: zod_1.z
            .object({
            retailUnitKey: zod_1.z.string().min(1),
            barcode: zod_1.z.string().min(1),
            fromPartition: zod_1.z.object({
                partitionNumber: zod_1.z.number(),
                previousQuantity: zod_1.z.number(),
            }),
            toPartition: zod_1.z.object({
                partitionNumber: zod_1.z.number(),
                isNewPartition: zod_1.z.boolean(),
                previousQuantity: zod_1.z.number(),
                deltaQuantity: zod_1.z.number(),
                currentQuantity: zod_1.z.number(),
            }),
        })
            .array(),
    }),
});
exports.stockMovedToPartitionFromVirtualBinSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('STOCK_MOVED_TO_PARTITION_FROM_VIRTUAL_BIN'),
    payload: zod_1.z.object({
        merchantId: zod_1.z.string().min(1),
        retailUnitKey: zod_1.z.string().min(1),
        barcode: zod_1.z.string().min(1),
        fromVirtualBin: zod_1.z.object({
            binId: zod_1.z.string().min(1),
            partitionNumber: zod_1.z.number(),
            previousQuantity: zod_1.z.number(),
            currentQuantity: zod_1.z.number(),
            delta: zod_1.z.number(),
        }),
        toBin: zod_1.z.object({
            binId: zod_1.z.string().min(1),
            partitionNumber: zod_1.z.number(),
            previousQuantity: zod_1.z.number(),
            currentQuantity: zod_1.z.number(),
            delta: zod_1.z.number(),
        }),
    }),
});
exports.virtualBinPartitionUpdateSchema = zod_1.z.object({
    barcode: zod_1.z.string().min(1),
    partitionNumber: zod_1.z.number(),
    retailUnitKey: zod_1.z.string().min(1),
    isNewPartition: zod_1.z.boolean().optional(),
    currentQuantity: zod_1.z.number(),
    previousQuantity: zod_1.z.number(),
    deltaQuantity: zod_1.z.number(),
});
exports.stockAddedToVirtualBinSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('STOCK_ADDED_TO_VIRTUAL_BIN'),
    payload: zod_1.z.object({
        binId: zod_1.z.string(),
        organisationId: zod_1.z.string(),
        partitions: exports.virtualBinPartitionUpdateSchema.array(),
    }),
});
exports.stockRemovedFromVirtualBinSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('STOCK_REMOVED_FROM_VIRTUAL_BIN'),
    payload: zod_1.z.object({
        binId: zod_1.z.string(),
        organisationId: zod_1.z.string(),
        partitions: exports.virtualBinPartitionUpdateSchema
            .omit({ isNewPartition: true })
            .array(),
    }),
});
exports.putawayItemsPlacedInPartitionSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PUTAWAY_ITEMS_PLACED_IN_PARTITION'),
    payload: zod_1.z.object({
        transferId: base_1.StringId,
        portId: base_1.portIdSchema,
        autostoreBinId: base_1.autostoreBinIdSchema,
        partitionNumber: zod_1.z.number().int().nonnegative(),
        retailUnitBarcode: zod_1.z.string().min(1),
        quantityAdded: zod_1.z.number().positive(),
        quantityInput: zod_1.z.number().optional(),
        currentQuantityInPartition: zod_1.z.number().optional(),
        hasBeenAdjusted: zod_1.z.boolean().optional(),
        originalQuantityAdded: zod_1.z.number().optional(),
    }),
});
exports.putawayItemsRemovedFromPartitionSchema = exports.putawayItemsPlacedInPartitionSchema.extend({
    type: zod_1.z.literal('PUTAWAY_ITEMS_REMOVED_FROM_PARTITION'),
    payload: exports.putawayItemsPlacedInPartitionSchema.shape.payload
        .omit({ quantityAdded: true })
        .extend({ quantityRemoved: zod_1.z.number().positive() }),
});
exports.PlaceOrRemoveItemsInPartitionFailed = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PUTAWAY_PLACE_OR_REMOVE_ITEMS_IN_PARTITION_FAILED'),
    payload: zod_1.z
        .object({
        transferId: base_1.StringId,
        portId: base_1.portIdSchema,
        partitionNumber: zod_1.z.number().int().nonnegative(),
        retailUnitBarcode: zod_1.z.string().min(1),
        quantity: zod_1.z.number().nonnegative(),
    })
        .and(zod_1.z.union([
        zod_1.z.object({
            reason: zod_1.z.literal('PORT_NOT_FOUND'),
        }),
        zod_1.z.object({
            reason: zod_1.z.literal('INVALID_PORT_TYPE'),
            expectedPortType: zod_1.z.literal('PUTAWAY'),
            actualPortType: model_1.portTypeSchema,
        }),
        zod_1.z.object({
            reason: zod_1.z.literal('NO_CURRENT_BIN'),
        }),
        zod_1.z.object({
            reason: zod_1.z.literal('NO_CURRENT_TRANSFER'),
            autostoreBinId: base_1.autostoreBinIdSchema,
        }),
        zod_1.z.object({
            reason: zod_1.z.literal('TRANSFER_ID_MISMATCH'),
            autostoreBinId: base_1.autostoreBinIdSchema,
            expectedTransferId: zod_1.z.string(),
            actualTransferId: zod_1.z.string(),
        }),
        zod_1.z.object({
            reason: zod_1.z.literal('BARCODE_DOES_NOT_MATCH_ANY_RETAIL_UNIT'),
            autostoreBinId: base_1.autostoreBinIdSchema,
        }),
        zod_1.z.object({
            reason: zod_1.z.literal('PARTITION_NOT_FOUND'),
            autostoreBinId: base_1.autostoreBinIdSchema,
            currentBinId: zod_1.z.string(),
        }),
        zod_1.z.object({
            reason: zod_1.z.literal('BARCODE_IS_DIFFERENT_FROM_BIN_ITEM'),
            expectedBarcode: zod_1.z.string(),
            actualBarcode: zod_1.z.string(),
        }),
    ])),
});
exports.StorageEvent = zod_1.z.union([
    exports.autostoreBinsCreatedSchema,
    exports.toteStorageCreatedSchema,
    exports.storageUnitsStatusChangedSchema,
    exports.totesEmptiedSchema,
    exports.cmcGenesysToteExitedSuccessfullySchema,
    exports.cmcGenesysToteSentToManualPackSchema,
    exports.sendCmcGenesysToteToManualPackFailedSchema,
    exports.StockInBinAdjusted,
    exports.stockAddedToEmptyPartitionSchema,
    exports.addStockToEmptyPartitionFailedSchema,
    exports.autostoreBinsInductedSchema,
    exports.autostoreBinLockedSchema,
    exports.autostoreBinUnlockedSchema,
    exports.partitionsMovedToVirtualBinSchema,
    exports.stockMovedToPartitionFromVirtualBinSchema,
    exports.stockAddedToVirtualBinSchema,
    exports.stockRemovedFromVirtualBinSchema,
    exports.putawayItemsPlacedInPartitionSchema,
    exports.PlaceOrRemoveItemsInPartitionFailed,
    exports.putawayItemsRemovedFromPartitionSchema,
]);
