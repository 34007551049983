"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.taskGroupCategorySchema = exports.hydratedTaskGroupSchema = exports.taskGroupWithPicklistsSchema = exports.taskGroupWithBinsSchema = exports.taskGroupSchema = exports.taskGroupStatusSchema = exports.autostoreTaskGroupIdSchema = exports.taskWithBinRequiredSchema = exports.taskWithBinSchema = exports.taskWithHydratedPicklistsSchema = exports.hydratedTaskPicklistSchema = exports.taskSchema = exports.pickCycleSchema = exports.taskPickSchema = exports.taskPicklistSchema = exports.TASK_GROUP_ENTITY_ID_PREFIX = void 0;
const z = __importStar(require("zod"));
const autostore_port_1 = require("./autostore-port");
const picklist_1 = require("./picklist");
const storage_1 = require("./storage");
exports.TASK_GROUP_ENTITY_ID_PREFIX = 'TG';
exports.taskPicklistSchema = z.object({
    picklistId: z.string().min(1),
    partitionNumber: z.number().positive(),
    quantity: z.number().positive(),
});
exports.taskPickSchema = z.object({
    state: z.enum(['PLANNED', 'COMPLETE']),
    picklistId: z.string().min(1),
    quantity: z.number().positive(),
});
exports.pickCycleSchema = z.object({
    partitionNumber: z.number().positive(),
    state: z.enum(['PLANNED', 'IN_PROGRESS', 'COMPLETE']),
    picks: z.array(exports.taskPickSchema),
});
exports.taskSchema = z.object({
    id: z.string().min(1),
    taskGroupId: z.string().min(1),
    warehouseId: z.string().min(1),
    autostoreTaskId: z.number().positive().optional(),
    order: z.number().positive(),
    binId: z.string(),
    picklists: z.array(exports.taskPicklistSchema),
    picklistIds: z.array(z.string()),
    partitions: z.array(z.object({
        partitionNumber: z.number().positive(),
        quantityAllocatedIncrease: z.number().positive(),
        hasBeenPicked: z.boolean(),
    })),
    completed: z.boolean(),
    state: z.enum(['PLANNED', 'IN_PROGRESS', 'COMPLETE']),
    pickCycles: z.array(exports.pickCycleSchema),
});
exports.hydratedTaskPicklistSchema = exports.taskPicklistSchema.extend({
    picklist: picklist_1.picklistSchema,
});
exports.taskWithHydratedPicklistsSchema = exports.taskSchema.extend({
    picklists: exports.hydratedTaskPicklistSchema.array(),
});
exports.taskWithBinSchema = exports.taskSchema.extend({
    bin: storage_1.autostoreBinSchema.optional(),
});
exports.taskWithBinRequiredSchema = exports.taskWithBinSchema.extend({
    bin: storage_1.autostoreBinSchema,
});
exports.autostoreTaskGroupIdSchema = z.number().positive();
exports.taskGroupStatusSchema = z.union([
    z.literal('AWAITING_BIN_ALLOCATION'),
    z.literal('AWAITING_AUTOSTORE_SYNC'),
    z.literal('AWAITING_PORT'),
    z.literal('PICKING'),
    z.literal('COMPLETE'),
    z.literal('CANCELLED'),
    z.literal('LOCKED_FOR_AUTOSTORE_SYNCING'),
    z.literal('SUSPENDED'),
]);
exports.taskGroupSchema = z.object({
    id: z.string().min(1),
    warehouseId: z.string().min(1),
    merchantId: z.string().min(1),
    autostoreTaskGroupId: exports.autostoreTaskGroupIdSchema.optional(),
    pickingMode: autostore_port_1.pickingModeStateSchema,
    priority: z.number().positive().optional(),
    picklistIds: z.array(picklist_1.picklistSchema.shape.id),
    tasks: z.array(exports.taskSchema),
    requestedTime: z.string().optional(),
    status: exports.taskGroupStatusSchema,
    port: z.optional(autostore_port_1.portSchema),
});
exports.taskGroupWithBinsSchema = exports.taskGroupSchema.extend({
    tasks: z.array(exports.taskWithBinSchema),
});
exports.taskGroupWithPicklistsSchema = exports.taskGroupSchema.extend({
    picklists: picklist_1.picklistSchema.array(),
});
exports.hydratedTaskGroupSchema = exports.taskGroupWithBinsSchema.and(exports.taskGroupWithPicklistsSchema);
/** @deprecated Use autostoreCategorySchema instead */
exports.taskGroupCategorySchema = z.union([
    z.literal(1),
    z.literal(2),
    z.literal(3), // Priority
]);
