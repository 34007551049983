"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.systemEventSchema = exports.mockMigratorEventSchema = exports.mockDeleteEventSchema = exports.mockEventSchema = exports.eventSkippedSchema = void 0;
const zod_1 = require("zod");
const base_1 = require("./base");
exports.eventSkippedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('EVENT_SKIPPED'),
    payload: zod_1.z.object({
        skippedEventId: zod_1.z.number().int(),
        skippedEventType: zod_1.z.string(),
        reason: zod_1.z.union([zod_1.z.literal('INCIDENT_RECOVERY'), zod_1.z.literal('OTHER')]),
        user: zod_1.z.string().email(),
    }),
});
/** Mock event used for integration testing, does nothing */
exports.mockEventSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('MOCK_EVENT'),
    payload: zod_1.z.object({}),
});
/** Mock event used for integration testing, does nothing */
exports.mockDeleteEventSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('MOCK_DELETE_EVENT'),
    payload: zod_1.z.object({}),
});
/** Mock event used for migrator testing, does nothing */
exports.mockMigratorEventSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('MOCK_MIGRATOR_EVENT'),
    version: zod_1.z.literal(2).default(2),
    payload: zod_1.z.object({
        id: zod_1.z.number(),
        warehouseName: zod_1.z.string().optional(),
        shapes: zod_1.z
            .object({
            name: zod_1.z.string(),
        })
            .array(),
    }),
});
exports.systemEventSchema = zod_1.z.union([
    exports.eventSkippedSchema,
    exports.mockEventSchema,
    exports.mockDeleteEventSchema,
    exports.mockMigratorEventSchema,
]);
