import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { PutawayPort } from 'api-schema/lib';
import { PortBinStateType } from 'api-schema/lib/model';
import { Button } from '../../../../../components/admin/Button';
import { Container } from '../../../../../components/admin/Container';
import {
  BinCheckButtons,
  BinCheckMessage,
} from '../BinCheck/BinCheck.elements';
import {
  SummaryItemSubtext,
  SummaryMissingQuantityText,
} from './Summary.elements';

type Props = {
  lastBin?: PortBinStateType;
  currentTransfer: PutawayPort['currentTransfer'];
  onCancelCompletePutaway: () => void;
  onConfirmCompletePutaway: () => void;
  useNumberInput: boolean;
};

export const Summary = ({
  currentTransfer,
  onCancelCompletePutaway,
  onConfirmCompletePutaway,
  lastBin,
  useNumberInput,
}: Props) => {
  // build array where item's putaway quantity less than required quantity
  const missing = (currentTransfer?.items || [])
    .map((item) => {
      if (!useNumberInput) {
        return item;
      }

      const transferQuantityPutaway = item.quantityPutaway || 0;
      const binQuantityPutaway = lastBin
        ? lastBin.partitions
            .filter((p) => p.retailUnitKey === item.retailUnitKey)
            .reduce((sum, p) => sum + (p.quantityPutaway || 0), 0)
        : 0;
      const quantityPutaway = transferQuantityPutaway + binQuantityPutaway;

      return {
        ...item,
        quantityPutaway,
      };
    })
    .filter(
      (item) => !item.quantityPutaway || item.quantityPutaway < item.quantity
    );

  const isMissing = missing.length > 0;

  return (
    <Container>
      {isMissing ? (
        <Table width="100%" style={{ marginBottom: '20px' }}>
          <TableHead>
            <TableRow>
              <TableCell>Missing item(s)</TableCell>
              <TableCell align="right">QTY</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {missing.map((m) => (
              <TableRow key={m.retailUnitKey}>
                <TableCell>
                  <Box fontSize="larger">
                    <strong>{m.retailUnit?.name}</strong>
                  </Box>
                  <SummaryItemSubtext>
                    SKU: {m.retailUnit?.sku}
                  </SummaryItemSubtext>
                  <SummaryItemSubtext>
                    Barcode: {m.retailUnit?.barcode}
                  </SummaryItemSubtext>
                </TableCell>
                <TableCell align="right">
                  <SummaryMissingQuantityText>
                    {m.quantity - (m.quantityPutaway || 0)}
                  </SummaryMissingQuantityText>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <BinCheckMessage $aspectRatio={25 / 9}>
          Are you sure you want to complete this transfer?
        </BinCheckMessage>
      )}
      <BinCheckButtons>
        <Button variant="heavy" onClick={onCancelCompletePutaway}>
          {isMissing ? 'BACK' : 'NO'}
        </Button>
        <Button
          variant="heavy"
          onClick={onConfirmCompletePutaway}
          data-testid="confirm-complete"
        >
          CONFIRM
        </Button>
      </BinCheckButtons>
    </Container>
  );
};
