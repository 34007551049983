"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transferEventSchema = exports.transferPutawayDirectlyToVirtualBinSchema = exports.transferArrivedSchema = exports.transferCancelledSchema = exports.transferShippedSchema = exports.transferCreatedSchema = exports.transferAcceptedSchema = exports.transferDestinationSchema = exports.transferOriginTypeSchema = exports.transferOriginSchema = exports.transferScannedSchema = void 0;
const zod_1 = require("zod");
const retailUnit_1 = require("../model/retailUnit");
const base_1 = require("./base");
exports.transferScannedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TRANSFER_SCANNED'),
    payload: zod_1.z.object({
        transferId: base_1.StringId,
        location: zod_1.z.object({
            portId: zod_1.z.number().int().positive(),
        }),
        putawayMode: base_1.putawayModeSchema.optional(),
        activeOnPorts: zod_1.z.number().int().array().optional(),
    }),
});
const transferCreatedRetailUnitSchema = zod_1.z.object({
    id: zod_1.z.string().min(1),
    externalId: zod_1.z.union([zod_1.z.string(), zod_1.z.number()]).optional(),
    merchantId: zod_1.z.string().min(1),
    barcode: zod_1.z.string().min(1),
    sku: zod_1.z.string().min(1).optional(),
    name: zod_1.z.string().min(1).optional(),
    heightMm: zod_1.z.number().int().nonnegative().optional(),
    widthMm: zod_1.z.number().int().nonnegative().optional(),
    depthMm: zod_1.z.number().int().nonnegative().optional(),
    weightGrams: zod_1.z.number().int().positive(),
    options: zod_1.z.array(zod_1.z.string()).optional(),
    imageSrc: zod_1.z.string().min(1).optional(),
});
const transferCreatedItemSchema = zod_1.z.object({
    quantity: zod_1.z.number().int().nonnegative(),
    quantityPutaway: zod_1.z.number().int().nonnegative().optional(),
    retailUnit: transferCreatedRetailUnitSchema,
});
const externalTransferLocation = zod_1.z.object({
    type: zod_1.z.literal('EXTERNAL'),
    id: zod_1.z.union([zod_1.z.number(), zod_1.z.string()]).optional(),
    transferNumberRef: zod_1.z.string().optional(),
});
const fulfilmentNetworkTransferLocation = zod_1.z.object({
    type: zod_1.z.literal('FULFILMENT_NETWORK'),
    locationType: zod_1.z.literal('FC'),
    locationId: base_1.StringId,
});
const virtualBinTransferLocation = zod_1.z.object({
    type: zod_1.z.literal('VIRTUAL_BIN'),
    virtualBinId: base_1.StringId,
    warehouseId: base_1.StringId,
});
exports.transferOriginSchema = zod_1.z.discriminatedUnion('type', [
    externalTransferLocation,
    virtualBinTransferLocation,
]);
const transferOrigins = exports.transferOriginSchema._def.options.map((s) => s.shape.type.value);
exports.transferOriginTypeSchema = zod_1.z.enum(transferOrigins);
exports.transferDestinationSchema = fulfilmentNetworkTransferLocation;
exports.transferAcceptedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TRANSFER_ACCEPTED'),
    version: zod_1.z.literal(1).default(1),
    transferId: base_1.StringId,
    organisationId: base_1.StringId,
    payload: zod_1.z.object({
        transferId: base_1.StringId,
        origin: exports.transferOriginSchema,
        destination: exports.transferDestinationSchema,
        shipment: zod_1.z.object({
            eta: zod_1.z.union([zod_1.z.string(), zod_1.z.date()]),
            trackingNumber: zod_1.z.string(),
            carrier: zod_1.z.string(),
        }),
        notes: zod_1.z.string().optional(),
        labelPath: base_1.LabelPaths.optional(),
        items: zod_1.z
            .object({
            retailUnitKey: base_1.StringId,
            barcode: base_1.StringId,
            quantity: zod_1.z.number().int().positive(),
            externalTransferItemId: zod_1.z.union([zod_1.z.string(), zod_1.z.number()]).optional(),
            virtualBinQuantityAllocatedForTransfer: zod_1.z
                .object({
                partitionNumber: zod_1.z.number().int(),
                current: zod_1.z.number().int(),
                delta: zod_1.z.number().int(),
                previous: zod_1.z.number().int(),
            })
                .optional(),
        })
            .array(),
        upsertRetailUnits: zod_1.z
            .object({
            id: retailUnit_1.retailUnitRecordKeySchema,
            barcode: zod_1.z.string().min(1),
            sku: zod_1.z.string().min(1).optional(),
            name: zod_1.z.string().min(1).optional(),
            heightMm: zod_1.z.number().int().nonnegative().optional(),
            widthMm: zod_1.z.number().int().nonnegative().optional(),
            depthMm: zod_1.z.number().int().nonnegative().optional(),
            weightGrams: zod_1.z.number().int().positive(),
            imageSrc: zod_1.z.string().min(1).optional(),
            options: zod_1.z.string().array().optional(),
        })
            .array()
            .optional(),
    }),
});
/**
 * TRANSFER_CREATED remains to support reading legacy events in
 * however, it should never be raised in this application
 * instead please see TRANSFER_ACCEPTED
 */
exports.transferCreatedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TRANSFER_CREATED'),
    version: zod_1.z.literal(2).default(2),
    transferId: zod_1.z.string().min(1),
    organisationId: zod_1.z.string().min(1),
    payload: zod_1.z.object({
        transferId: base_1.StringId,
        items: transferCreatedItemSchema.array(),
        externalId: zod_1.z.union([zod_1.z.string(), zod_1.z.number()]).optional(),
        shipment: zod_1.z
            .object({
            eta: zod_1.z.union([zod_1.z.string(), zod_1.z.date()]),
            trackingNumber: zod_1.z.string(),
            carrier: zod_1.z.string(),
        })
            // TODO(WMS-735): address orphaned todo
            // eslint-disable-next-line todo-plz/ticket-ref
            // TODO: ideally this would be required, but for versioning sake it isn't
            .optional(),
        notes: zod_1.z.string().optional(),
        isTest: zod_1.z.boolean().optional(),
        labelPath: base_1.LabelPaths.optional(),
        //TODO(SSP-4764): Remove merchantId once downstream is updated to organisationId for compatibility
        merchantId: zod_1.z.string().optional(),
        organisationId: zod_1.z.string(),
        integrations: zod_1.z
            .object({
            ssp: zod_1.z
                .object({
                id: zod_1.z.union([zod_1.z.string(), zod_1.z.number()]),
                transferNumberRef: zod_1.z.string(),
            })
                .optional(),
        })
            .optional(),
    }),
});
exports.transferShippedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TRANSFER_SHIPPED'),
    payload: zod_1.z.object({
        transferId: base_1.StringId,
    }),
});
exports.transferCancelledSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TRANSFER_CANCELLED'),
    payload: zod_1.z.object({
        transfer: zod_1.z.object({
            id: base_1.StringId,
            origin: exports.transferOriginSchema.optional(),
            virtualBinQuantityAllocatedForTransfer: zod_1.z
                .object({
                partitionNumber: zod_1.z.number().int(),
                retailUnitKey: retailUnit_1.retailUnitRecordKeySchema,
                current: zod_1.z.number().int(),
                delta: zod_1.z.number().int(),
                previous: zod_1.z.number().int(),
            })
                .array()
                .optional(),
        }),
    }),
});
exports.transferArrivedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TRANSFER_ARRIVED'),
    payload: zod_1.z.object({
        transferId: base_1.StringId,
    }),
});
exports.transferPutawayDirectlyToVirtualBinSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TRANSFER_PUTAWAY_DIRECTLY_TO_VIRTUAL_BIN'),
    transferId: base_1.StringId,
    organisationId: base_1.StringId,
    payload: zod_1.z.object({
        binId: base_1.StringId,
        itemsPutaway: zod_1.z
            .object({
            retailUnitKey: retailUnit_1.retailUnitRecordKeySchema,
            barcode: base_1.StringId,
            quantityIncoming: zod_1.z.number().int().positive(),
            quantityPutaway: zod_1.z.number().int().nonnegative(),
            partitionPutaway: zod_1.z.object({
                partitionNumber: zod_1.z.number(),
                isNewPartition: zod_1.z.boolean(),
                currentQuantity: zod_1.z.number(),
                previousQuantity: zod_1.z.number(),
                deltaQuantity: zod_1.z.number(),
            }),
        })
            .array(),
    }),
});
exports.transferEventSchema = zod_1.z.union([
    exports.transferAcceptedSchema,
    exports.transferCreatedSchema,
    exports.transferArrivedSchema,
    exports.transferScannedSchema,
    exports.transferShippedSchema,
    exports.transferCancelledSchema,
    exports.transferPutawayDirectlyToVirtualBinSchema,
]);
