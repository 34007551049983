"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.putawayTransferDirectlyToVirtualBinRequestSchema = void 0;
const zod_1 = require("zod");
const base_1 = require("../events/base");
exports.putawayTransferDirectlyToVirtualBinRequestSchema = zod_1.z.object({
    itemsToPutaway: zod_1.z
        .object({ barcode: base_1.StringId, quantityPutaway: zod_1.z.number().int().positive() })
        .array(),
});
