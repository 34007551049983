"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeStockFromVirtualBinOutcomeSchema = exports.addStockToVirtualBinOutcomeSchema = exports.addStockToVirtualBinFailureSchema = exports.moveStockToPartitionFromVirtualBinOutcomeSchema = exports.movePartitionsToVirtualBinOutcomeSchema = void 0;
const zod_1 = require("zod");
const storage_1 = require("../../events/storage");
const generic_1 = require("./generic");
const merchant_1 = require("./merchant");
const warehouse_1 = require("./warehouse");
exports.movePartitionsToVirtualBinOutcomeSchema = zod_1.z.discriminatedUnion('outcome', [
    zod_1.z.object({ outcome: zod_1.z.literal('WAREHOUSE_NOT_FOUND') }),
    zod_1.z.object({ outcome: zod_1.z.literal('MERCHANT_NOT_FOUND') }),
    zod_1.z.object({ outcome: zod_1.z.literal('BIN_NOT_FOUND') }),
    zod_1.z.object({ outcome: zod_1.z.literal('MERCHANT_HAS_NO_VIRTUAL_BIN') }),
    zod_1.z.object({
        outcome: zod_1.z.literal('PARTITIONS_DO_NOT_EXIST'),
        partitionNumbers: zod_1.z.number().array(),
    }),
    zod_1.z.object({
        outcome: zod_1.z.literal('PARTITIONS_HAVE_QUANTITY_ALLOCATED'),
        allocatedPartitions: zod_1.z.number().array(),
    }),
    zod_1.z.object({
        outcome: zod_1.z.literal('PARTITIONS_HAVE_NO_RETAIL_UNIT'),
        partitions: zod_1.z.number().array(),
    }),
    zod_1.z.object({
        outcome: zod_1.z.literal('SUCCESS'),
        autostoreBinId: zod_1.z.number(),
        toVirtualBinId: zod_1.z.string(),
        binNowEmpty: zod_1.z.boolean(),
        partitionsToMove: storage_1.partitionsMovedToVirtualBinSchema.shape.payload.shape.partitionsMoved,
    }),
]);
exports.moveStockToPartitionFromVirtualBinOutcomeSchema = zod_1.z.discriminatedUnion('outcome', [
    generic_1.Success.extend({
        toBin: storage_1.stockMovedToPartitionFromVirtualBinSchema.shape.payload.shape.toBin,
        fromVirtualBin: storage_1.stockMovedToPartitionFromVirtualBinSchema.shape.payload.shape
            .fromVirtualBin,
    }),
    warehouse_1.warehouseNotFoundOutcomeSchema,
    merchant_1.merchantNotFoundOutcomeSchema,
    zod_1.z.object({ outcome: zod_1.z.literal('MERCHANT_HAS_NO_VIRTUAL_BIN') }),
    zod_1.z.object({ outcome: zod_1.z.literal('BIN_NOT_FOUND') }),
    zod_1.z.object({ outcome: zod_1.z.literal('BIN_NOT_USED_BY_MERCHANT') }),
    zod_1.z.object({ outcome: zod_1.z.literal('UNKNOWN_RETAIL_UNIT') }),
    zod_1.z.object({ outcome: zod_1.z.literal('INSUFFICIENT_STOCK_IN_VIRTUAL_BIN') }),
    zod_1.z.object({ outcome: zod_1.z.literal('NO_AVAILABLE_DESTINATION_PARTITION') }),
]);
exports.addStockToVirtualBinFailureSchema = zod_1.z.discriminatedUnion('outcome', [
    merchant_1.merchantNotFoundOutcomeSchema,
    zod_1.z.object({ outcome: zod_1.z.literal('MERCHANT_HAS_NO_VIRTUAL_BIN') }),
    zod_1.z.object({
        outcome: zod_1.z.literal('UNKNOWN_RETAIL_UNITS'),
        barcodes: zod_1.z.string().array(),
    }),
]);
exports.addStockToVirtualBinOutcomeSchema = zod_1.z.discriminatedUnion('outcome', [
    generic_1.Success.extend({
        binId: zod_1.z.string().min(1),
        partitions: storage_1.stockAddedToVirtualBinSchema.shape.payload.shape.partitions,
    }),
    ...exports.addStockToVirtualBinFailureSchema.options,
]);
exports.removeStockFromVirtualBinOutcomeSchema = zod_1.z.discriminatedUnion('outcome', [
    generic_1.Success.extend({
        binId: zod_1.z.string().min(1),
        partitions: storage_1.stockRemovedFromVirtualBinSchema.shape.payload.shape.partitions,
    }),
    merchant_1.merchantNotFoundOutcomeSchema,
    zod_1.z.object({ outcome: zod_1.z.literal('MERCHANT_HAS_NO_VIRTUAL_BIN') }),
    zod_1.z.object({
        outcome: zod_1.z.literal('UNKNOWN_RETAIL_UNITS'),
        barcodes: zod_1.z.string().array(),
    }),
    zod_1.z.object({
        outcome: zod_1.z.literal('RETAIL_UNIT_INVENTORY_NOT_FOUND'),
        barcodes: zod_1.z.string().array(),
    }),
    zod_1.z.object({
        outcome: zod_1.z.literal('RETAIL_UNITS_NOT_IN_VIRTUAL_BIN'),
        barcodes: zod_1.z.string().array(),
    }),
    zod_1.z.object({
        outcome: zod_1.z.literal('INSUFFICIENT_AVAILABLE_INVENTORY_DUE_TO_ALLOCATED_STOCK'),
        barcodes: zod_1.z.string().array(),
    }),
    zod_1.z.object({
        outcome: zod_1.z.literal('INSUFFICIENT_STOCK_IN_VIRTUAL_PARTITION'),
        partitions: storage_1.stockRemovedFromVirtualBinSchema.shape.payload.shape.partitions,
    }),
]);
