import { PartitionsPerBin } from 'api-schema';
import { getRetailUnitKey } from 'api-schema/lib/model';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePutawayPortSocket } from '../../../components/common/SocketProvider';
import { AutoStoreResumed } from '../../../components/warehouse/AutoStoreResumed/AutoStoreResumed';
import { AutoStoreStoppedWorking } from '../../../components/warehouse/AutoStoreStoppedWorking/AutoStoreStoppedWorking';
import { Button } from '../../../components/warehouse/Button';
import { SnackbarDefaults } from '../../../constants/snackbarDefaults';
import { useAppState } from '../../../store';
import { setInterruptState, setTargetPartition } from '../../../store/actions';
import { getCurrentBinWeightInGrams } from '../../../store/selectors';
import { handleCommandRejectedResultWithSnackbar } from '../../../utils/commands';
import { PutawayTransferView } from './PutawayTransfer.view';

export interface TransferProgress {
  [sku: string]: number; // keep track of how many of a sku has been scanned
}

export const PutawayTransfer = () => {
  const {
    appDispatch,
    appState: {
      portState,
      interruptState,
      targetPartition,
      currentWarehouse,
      featureFlags,
    },
  } = useAppState();

  const port = portState?.type === 'PUTAWAY' ? portState : undefined;

  const { dispatchCommand, subscribeToEvents } = usePutawayPortSocket();
  const { enqueueSnackbar } = useSnackbar();
  const [nextBinPartitions, setNextBinPartitions] =
    useState<PartitionsPerBin>(1);
  const [scanInputEl, setScanInputEl] = useState<HTMLInputElement | undefined>(
    undefined
  );
  const [barcodeInput, setBarcodeInput] = useState('');
  const [isShowingSummary, setIsShowingSummary] = useState(false);
  const [hasAutoStoreError, setHasAutoStoreError] = useState(false);

  const inputRetailUnitKey = useMemo(() => {
    return getRetailUnitKey(
      port?.currentTransfer?.organisationId ?? '',
      barcodeInput
    );
  }, [barcodeInput, port]);

  const isScanInput =
    portState?.type === 'PUTAWAY' && portState?.putawayMode === 'SCAN';

  useEffect(() => {
    const status = currentWarehouse?.autostore?.status;
    const isError = Boolean(status && status !== 'STARTED');
    if (!hasAutoStoreError && isError) {
      setHasAutoStoreError(true);
    }
  }, [
    hasAutoStoreError,
    setHasAutoStoreError,
    currentWarehouse?.autostore?.status,
  ]);

  // call this function to focus the scan unit input field
  const focusScanInput = useCallback(() => {
    if (scanInputEl?.focus) {
      scanInputEl.focus();
    }
  }, [scanInputEl]);

  // when target partition changes, we want to refocus the input field
  useEffect(() => {
    if (!isScanInput) {
      const targetBarcode =
        portState?.currentBin?.partitions.find(
          (p) => p.partitionNumber === targetPartition
        )?.retailUnit?.barcode || '';
      setBarcodeInput(targetBarcode);
    }

    focusScanInput();
  }, [targetPartition, focusScanInput, portState, isScanInput]);

  const onClearBarcode = () => {
    focusScanInput();
    setBarcodeInput('');
  };

  const handleChangeBarcode = (value: string) => {
    setBarcodeInput(value);
  };

  const onSetBinConfiguration = async () => {
    const configurePartitionsResponse = await dispatchCommand({
      type: 'CONFIGURE_PARTITIONS',
      numberOfPartitions: nextBinPartitions,
    });

    if (configurePartitionsResponse.result.outcome !== 'SUCCESS') {
      handleCommandRejectedResultWithSnackbar(
        enqueueSnackbar,
        configurePartitionsResponse
      );
      return;
    }
    appDispatch(setInterruptState(undefined));
    appDispatch(setTargetPartition(1));
  };

  const onCheckBinHasArrived = async () => {
    enqueueSnackbar(`Checking if bin has arrived at port`, SnackbarDefaults);
    const checkBinHasArrivedResult = await dispatchCommand({
      type: 'CHECK_PUTAWAY_BIN_HAS_ARRIVED',
    });
    if (checkBinHasArrivedResult.result.outcome !== 'SUCCESS') {
      enqueueSnackbar(
        'There was a problem confirming bin has arrived. Please alert your Manager.'
      );
    }
  };

  const { appState } = useAppState();
  const isManualBinArrivedEnabled = appState.featureFlags?.confirmBinHasArrived
    ? appState.featureFlags.confirmBinHasArrived
    : false;

  const onReceiveBin = () => {
    appDispatch(setInterruptState('EMPTY_CHECK'));
  };

  const onItemAdded = () => {
    enqueueSnackbar(`Item added successfully`, SnackbarDefaults);
  };

  const onItemRemoved = () => {
    enqueueSnackbar(`Item removed successfully`, SnackbarDefaults);
  };

  const onItemQuantityUpdated = () => {
    const newQuantity = portState?.currentBin?.partitions.find(
      (p) => p.partitionNumber === targetPartition
    )?.quantityPutaway;
    if (newQuantity) {
      enqueueSnackbar(`Quantity updated to ${newQuantity}`, SnackbarDefaults);
    } else {
      enqueueSnackbar(`Partition reset`, SnackbarDefaults);
    }
  };

  const onPutawayCompleted = () => {
    enqueueSnackbar(`Putaway completed successfully`, SnackbarDefaults);
  };

  const onSwapBin = () => {
    appDispatch(setInterruptState('SWAP_CHECK'));
  };

  const onDoSwapBin = async () => {
    // loading indicators. disable stuff. etc
    appDispatch(setInterruptState(undefined));
    const commandResult = await dispatchCommand({
      type: 'SWAP_BIN',
    });
    handleCommandRejectedResultWithSnackbar(enqueueSnackbar, commandResult);
  };

  const onCancelSwapBin = () => {
    appDispatch(setInterruptState(undefined));
  };

  const onCompletePutaway = () => {
    setIsShowingSummary(true);
  };

  const onCancelCompletePutaway = () => {
    setIsShowingSummary(false);
  };

  const onConfirmCompletePutaway = async () => {
    setIsShowingSummary(false);
    const commandResult = await dispatchCommand({
      type: 'COMPLETE_PUTAWAY',
    });
    handleCommandRejectedResultWithSnackbar(enqueueSnackbar, commandResult);
  };

  const handleEmptyCheck = (isEmpty: boolean) => {
    appDispatch(setInterruptState('PARTITION_CHECK'));
    dispatchCommand({
      type: 'CHECK_BIN_EMPTINESS',
      isEmpty,
    });
  };

  const handleSetItemQuantity = async (quantity: number) => {
    if (!port?.currentTransfer) {
      enqueueSnackbar('Please scan the Transfer barcode', {
        ...SnackbarDefaults,
        variant: 'error',
      });
      return;
    }

    const activePartition = portState?.currentBin?.partitions.find(
      (p) => p.partitionNumber === targetPartition
    );
    if (!activePartition) {
      enqueueSnackbar('Please select a partition', {
        ...SnackbarDefaults,
        variant: 'error',
      });
      return;
    }

    const retailUnit = port?.currentTransfer.items.find(
      (i) => i.retailUnitKey === inputRetailUnitKey
    )?.retailUnit;
    if (!retailUnit) {
      enqueueSnackbar(
        'Scanned barcode does not match any item in the current transfer.',
        {
          ...SnackbarDefaults,
          variant: 'error',
        }
      );
      return;
    }

    dispatchCommand({
      type: 'PLACE_ITEMS_IN_PARTITION',
      partitionNumber: targetPartition,
      barcode: barcodeInput,
      transferId: port.currentTransfer.id,
      quantity,
    }).then((commandResult) => {
      handleCommandRejectedResultWithSnackbar(enqueueSnackbar, commandResult);
    });
  };

  const handleBarcodeFullyScanned = () => {
    if (!port?.currentTransfer) {
      enqueueSnackbar('Please scan the Transfer barcode', {
        ...SnackbarDefaults,
        variant: 'error',
      });
      return;
    }

    const activePartition = portState?.currentBin?.partitions.find(
      (p) => p.partitionNumber === targetPartition
    );
    if (!activePartition) {
      enqueueSnackbar('Please select a partition', {
        ...SnackbarDefaults,
        variant: 'error',
      });
      return;
    }

    const retailUnit = port?.currentTransfer.items.find(
      (i) => i.retailUnitKey === inputRetailUnitKey
    )?.retailUnit;
    if (!retailUnit) {
      enqueueSnackbar(
        'Scanned barcode does not match any item in the current transfer.',
        {
          ...SnackbarDefaults,
          variant: 'error',
        }
      );
      return;
    }

    const isFirstScan = !activePartition.retailUnit?.barcode;
    if (isFirstScan) {
      dispatchCommand({
        type: 'PLACE_ITEMS_IN_PARTITION',
        partitionNumber: targetPartition,
        barcode: barcodeInput,
        transferId: port.currentTransfer.id,
        quantity: 1,
      }).then((commandResult) =>
        handleCommandRejectedResultWithSnackbar(enqueueSnackbar, commandResult)
      );
      return;
    }

    if (activePartition.retailUnit?.barcode === barcodeInput) {
      enqueueSnackbar('Barcode already scanned, please update the quantity', {
        ...SnackbarDefaults,
        variant: 'warning',
      });
      return;
    }

    // different barcode, change the item to the new one with a warning
    enqueueSnackbar(
      `Changing the barcode from ${activePartition.retailUnit?.barcode} to ${barcodeInput}`,
      {
        ...SnackbarDefaults,
        variant: 'warning',
      }
    );
    dispatchCommand({
      type: 'PLACE_ITEMS_IN_PARTITION',
      partitionNumber: targetPartition,
      barcode: barcodeInput,
      transferId: port.currentTransfer.id,
      quantity: 1,
    }).then((commandResult) =>
      handleCommandRejectedResultWithSnackbar(enqueueSnackbar, commandResult)
    );
  };

  const handleChangeItem = async (
    type: 'ADD_PUTAWAY_ITEM' | 'REMOVE_PUTAWAY_ITEM'
  ) => {
    if (!port?.currentTransfer) {
      return;
    }

    const activePartition = portState?.currentBin?.partitions.find(
      (p) => p.partitionNumber === targetPartition
    );

    if (!activePartition) {
      enqueueSnackbar('Please select a partition', {
        ...SnackbarDefaults,
        variant: 'error',
      });
      return;
    }

    if (type === 'ADD_PUTAWAY_ITEM') {
      // find item with barcode that matches whats in input
      const retailUnit = port?.currentTransfer?.items.find(
        (i) => i.retailUnitKey === inputRetailUnitKey
      )?.retailUnit;

      if (!retailUnit) {
        enqueueSnackbar(
          'Scanned barcode does not match any item in the current transfer.',
          {
            ...SnackbarDefaults,
            variant: 'error',
          }
        );
        setBarcodeInput('');
        focusScanInput();
        return;
      }

      // Check if this item is already exists in another partition
      const existingPartition = portState?.currentBin?.partitions.find(
        (p) =>
          p?.retailUnit?.id === retailUnit?.id &&
          p.partitionNumber !== activePartition?.partitionNumber
      );

      if (existingPartition) {
        enqueueSnackbar(
          `Please use partition ${existingPartition.partitionNumber} for this retail unit.`,
          {
            ...SnackbarDefaults,
            variant: 'error',
          }
        );
        setBarcodeInput('');
        focusScanInput();
        return;
      }
    }

    if (
      activePartition?.retailUnit === undefined &&
      type === 'REMOVE_PUTAWAY_ITEM'
    ) {
      console.error(
        `Attempted to remove item from invalid partition: ${targetPartition}`
      );
      return;
    }
    const barcode =
      type === 'REMOVE_PUTAWAY_ITEM'
        ? activePartition!.retailUnit!.barcode // Using ! because we checked above
        : barcodeInput;

    dispatchCommand({
      type,
      partitionNumber: targetPartition,
      barcode,
      transferId: port.currentTransfer.id,
    }).then((commandResult) =>
      handleCommandRejectedResultWithSnackbar(enqueueSnackbar, commandResult)
    );

    if (isScanInput) {
      setBarcodeInput('');
    }
    focusScanInput();
  };

  useEffect(() => {
    return subscribeToEvents((event) => {
      switch (event.type) {
        case 'BIN_ARRIVED': {
          onReceiveBin();
          break;
        }
        case 'PUTAWAY_ITEM_ADDED': {
          onItemAdded();
          break;
        }
        case 'PUTAWAY_ITEM_REMOVED': {
          onItemRemoved();
          break;
        }
        case 'PUTAWAY_COMPLETED': {
          onPutawayCompleted();
          break;
        }
        case 'PUTAWAY_ITEMS_PLACED_IN_PARTITION':
        case 'PUTAWAY_ITEMS_REMOVED_FROM_PARTITION': {
          onItemQuantityUpdated();
          break;
        }
      }
    });
  });

  const onPartitionClick = (partition: number) => {
    appDispatch(setTargetPartition(partition));
    focusScanInput();
  };

  const activePartition = portState?.currentBin?.partitions.find(
    (p) => p.partitionNumber === targetPartition
  );
  const activeBarcode = activePartition?.retailUnit?.barcode;
  const activeProduct = port?.currentTransfer?.items.find(
    (i) => i.retailUnitKey === activePartition?.retailUnitKey
  );

  // TODO(WMS-735): address orphaned todo
  // eslint-disable-next-line todo-plz/ticket-ref
  // TODO: get the type from zod in shared. TODO: change zod in shared to be PartitionsPerBin
  const activeBinPartitions = portState?.currentBin?.partitions
    .length as PartitionsPerBin;

  const binWeight = getCurrentBinWeightInGrams(portState?.currentBin);

  if (hasAutoStoreError && currentWarehouse?.autostore?.status === 'STARTED') {
    return (
      <AutoStoreResumed
        Button={
          <Button onClick={() => setHasAutoStoreError(false)}>
            Continue Putaway
          </Button>
        }
      />
    );
  }

  if (hasAutoStoreError) {
    const autoStore = currentWarehouse?.autostore;

    if (autoStore?.status === 'UNREACHABLE') {
      return (
        <AutoStoreStoppedWorking
          genericReason="Unreachable"
          errorText="Putaway will resume once the AutoStore is active again."
        />
      );
    }

    return (
      <AutoStoreStoppedWorking
        errorText="Putaway will resume once the AutoStore is active again."
        error={{
          stopCode: String(autoStore?.state?.stopCode || ''),
          mode: String(autoStore?.state?.mode || ''),
          chargePercent: String(autoStore?.state?.chargePercent ?? '0'),
          reason: String(autoStore?.state?.stopReason || ''),
          status: String(autoStore?.status || ''),
        }}
      />
    );
  }

  return (
    <PutawayTransferView
      portState={port}
      interruptState={interruptState}
      onCheckBinEmptiness={handleEmptyCheck}
      onCancelSwapBin={onCancelSwapBin}
      onDoSwapBin={onDoSwapBin}
      nextBinPartitions={nextBinPartitions}
      onSetBinConfiguration={onSetBinConfiguration}
      onCheckBinHasArrived={onCheckBinHasArrived}
      isManualBinArrivedEnabled={isManualBinArrivedEnabled}
      setNextBinPartitions={setNextBinPartitions}
      activeBinPartitions={activeBinPartitions}
      onSwapBin={onSwapBin}
      setScanInputEl={setScanInputEl}
      onPartitionClick={onPartitionClick}
      targetPartition={targetPartition}
      onCompletePutaway={onCompletePutaway}
      activeProduct={activeProduct?.retailUnit}
      activeBarcode={activeBarcode}
      onChangeItem={handleChangeItem}
      onBarcodeChange={handleChangeBarcode}
      retailUnitBarcode={barcodeInput}
      partitions={portState?.currentBin?.partitions}
      onClearBarcode={onClearBarcode}
      binWeight={binWeight}
      isShowingSummary={isShowingSummary}
      onConfirmCompleteTransfer={onConfirmCompletePutaway}
      onCancelCompleteTransfer={onCancelCompletePutaway}
      hasActiveTransfer={port?.currentTransfer !== undefined}
      onSetItemQuantity={handleSetItemQuantity}
      onBarcodeFullyScanned={handleBarcodeFullyScanned}
      lastBin={port?.currentBin}
      featureFlags={featureFlags}
    />
  );
};
