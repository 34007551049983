import { Box, Divider, Typography } from '@material-ui/core';
import { Container } from '../../../../../components/admin/Container';

import { ArrowBack, CloseRounded, Undo } from '@material-ui/icons';
import {
  PartitionsPerBin,
  PartitionWithRetailUnit,
  RetailUnit,
} from 'api-schema';
import { Button } from '../../../../../components/admin/Button';
import { Input } from '../../../../../components/admin/Input';
import { MAX_BIN_WEIGHT_GRAMS } from '../../../../../constants/bin';
import { FormRow } from '../../../../../elements/admin/FormRow';
import { ProductDetails } from '../ProductDetails';
import { UpdateQuantityButton } from '../UpdateQuantityButton/UpdateQuantityButton';
import {
  PartitionBox,
  PartitionContainer,
  PartitionSubtext,
} from './BinGuide.elements';

type Props = {
  binId: number;
  numPartitions: PartitionsPerBin;
  targetPartition: number;
  retailUnitBarcode: string;
  partitions?: PartitionWithRetailUnit[];
  activePartition?: PartitionWithRetailUnit;
  activeProduct?: RetailUnit;
  binWeight: number;
  onClearBarcodeClick: () => void;
  onSwapBin: () => void;
  onUndoClick: () => void;
  onPartitionClick: (partition: number) => void;
  setScanInputEl: (ref: HTMLInputElement | undefined) => void;
  onBarcodeChange: (value: string) => void;
  onSubmit: () => void;
  onSetItemQuantity: (quantity: number) => void;
  onBarcodeFullyScanned: () => void;
  useNumberInput: boolean;
};

export const BinGuideView = ({
  binId,
  numPartitions,
  onSwapBin,
  targetPartition,
  onClearBarcodeClick,
  onUndoClick,
  retailUnitBarcode,
  onPartitionClick,
  setScanInputEl,
  partitions,
  activeProduct,
  onBarcodeChange,
  onSubmit,
  binWeight,
  activePartition,
  onSetItemQuantity,
  onBarcodeFullyScanned,
  useNumberInput,
}: Props) => {
  const handleEnterPress = (key: string) => {
    if (key === 'Enter') {
      if (useNumberInput) {
        onBarcodeFullyScanned();
      } else {
        onSubmit();
      }
    }
  };

  const handleClearBarcode = () => {
    if (useNumberInput) {
      onSetItemQuantity(0);
    } else {
      onClearBarcodeClick();
    }
  };

  return (
    <>
      <Container>
        <Box display="flex" justifyContent="space-between">
          <Typography>
            <strong>Bin: {binId}</strong>
          </Typography>
          <Typography>
            <strong>
              {(binWeight / 1000).toFixed(2)} / {MAX_BIN_WEIGHT_GRAMS / 1000}{' '}
              kgs
            </strong>
          </Typography>
        </Box>
        <Divider />
        <PartitionContainer $numPartitions={numPartitions}>
          {Array.from({ length: numPartitions }, (_, i) => {
            const partition = partitions?.find(
              (p) => p.partitionNumber === i + 1
            );
            const isTarget = i + 1 === targetPartition;
            const barcode = partition?.retailUnit?.barcode;
            const isEmpty = !barcode;
            let text = isTarget ? 'PLACE HERE' : 'EMPTY'; //defaults
            if (barcode) {
              text = barcode;
            }
            return (
              <PartitionBox
                key={i}
                $numPartitions={numPartitions}
                $target={isTarget}
                onClick={() => onPartitionClick(i + 1)}
                className={`${isTarget ? 'active' : ''} ${
                  isEmpty ? 'empty' : ''
                }`}
                data-testid={`partition-box-${i}`}
              >
                {text}
                {!isEmpty && (
                  <PartitionSubtext>
                    QTY: {partition?.quantityPutaway || 0}
                  </PartitionSubtext>
                )}
              </PartitionBox>
            );
          })}
        </PartitionContainer>
        <Box mb={3}>
          <FormRow>
            <Input
              fullWidth
              label="Scan retail unit"
              endIcon={<CloseRounded />}
              onEndIconClick={handleClearBarcode}
              value={retailUnitBarcode}
              InputProps={{
                onKeyPress: (e) => handleEnterPress(e.key),
                'aria-label': 'barcode-input',
              }}
              onInputRef={(el: HTMLInputElement | undefined) =>
                setScanInputEl(el)
              }
              onChange={({ target: { value } }) => {
                onBarcodeChange(value);
              }}
              name="barcode"
            />
            {useNumberInput ? (
              <UpdateQuantityButton
                quantity={activePartition?.quantityPutaway || 0}
                onUpdateQuantity={onSetItemQuantity}
              />
            ) : (
              <>
                <Typography align="center">
                  QTY
                  <br />
                  <Typography variant="h6">
                    {activePartition?.quantity}
                  </Typography>
                </Typography>
                <Button
                  disabled={activePartition?.quantity === 0}
                  onClick={onUndoClick}
                >
                  <Undo />
                </Button>
              </>
            )}
          </FormRow>
        </Box>
        <FormRow>
          <Button
            variant="heavy"
            fullWidth
            onClick={onSwapBin}
            icon={ArrowBack}
            alignIcon="left"
            data-testid="next-bin"
          >
            Next Bin
          </Button>
        </FormRow>
      </Container>
      <ProductDetails activeProduct={activeProduct} />
    </>
  );
};
