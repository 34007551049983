"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskGroupEvent = exports.unsuspendTaskGroupFailedSchema = exports.suspendTaskGroupFailedSchema = exports.taskGroupUnsuspendedSchema = exports.taskGroupSuspendedSchema = exports.picklistsUnassignedFromTaskGroupSchema = exports.autostoreSyncFailedSchema = exports.autostoreSyncStartedSchema = exports.taskGroupCancelledSchema = exports.TaskPartitionPicksCancelled = exports.TaskGroupTaskCancelled = exports.taskGroupBinsUnallocatedSchema = exports.TaskGroupCompleted = exports.taskGroupAutostoreSyncFailedEventSchema = exports.taskGroupAutostoreSyncSucceededSchema = exports.taskGroupBinsAllocatedSchema = exports.taskGroupsCreatedSchema = void 0;
const zod_1 = require("zod");
const error_1 = require("../autostore-client/error");
const base_1 = require("./base");
const taskSchema = zod_1.z.object({
    id: zod_1.z.string().min(1),
    taskGroupId: zod_1.z.string().min(1),
    warehouseId: zod_1.z.string().min(1),
    autostoreTaskId: zod_1.z.number().positive().optional(),
    order: zod_1.z.number().positive(),
    binId: zod_1.z.string(),
    picklists: zod_1.z.array(zod_1.z.object({
        picklistId: zod_1.z.string().min(1),
        partitionNumber: zod_1.z.number().positive(),
        quantity: zod_1.z.number().positive(),
    })),
    picklistIds: zod_1.z.array(zod_1.z.string()),
    partitions: zod_1.z.array(zod_1.z.object({
        partitionNumber: zod_1.z.number().positive(),
        quantityAllocatedIncrease: zod_1.z.number().positive(),
        hasBeenPicked: zod_1.z.boolean(),
    })),
    completed: zod_1.z.boolean(),
});
const taskGroupStatusSchema = zod_1.z.union([
    zod_1.z.literal('AWAITING_BIN_ALLOCATION'),
    zod_1.z.literal('AWAITING_AUTOSTORE_SYNC'),
    zod_1.z.literal('AWAITING_PORT'),
    zod_1.z.literal('PICKING'),
    zod_1.z.literal('COMPLETE'),
    zod_1.z.literal('CANCELLED'),
    zod_1.z.literal('LOCKED_FOR_AUTOSTORE_SYNCING'),
    zod_1.z.literal('SUSPENDED'),
]);
const taskGroupSchema = zod_1.z.object({
    id: zod_1.z.string().min(1),
    warehouseId: zod_1.z.string().min(1),
    merchantId: zod_1.z.string().min(1),
    autostoreTaskGroupId: zod_1.z.number().positive().optional(),
    pickingMode: base_1.pickingModeEventSchema,
    priority: zod_1.z.number().positive().optional(),
    picklistIds: zod_1.z.array(zod_1.z.string().min(1)),
    tasks: zod_1.z.array(taskSchema),
    requestedTime: zod_1.z.string().optional(),
    status: taskGroupStatusSchema,
});
exports.taskGroupsCreatedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TASK_GROUPS_CREATED'),
    version: zod_1.z.literal(3).default(3),
    payload: zod_1.z.object({
        taskGroups: zod_1.z.array(taskGroupSchema),
    }),
});
exports.taskGroupBinsAllocatedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TASK_GROUP_BINS_ALLOCATED'),
    payload: zod_1.z.object({
        taskGroupId: zod_1.z.string().min(1),
        tasks: zod_1.z.array(zod_1.z.object({
            id: zod_1.z.string().min(1),
            binId: zod_1.z.string().min(1),
            partitions: zod_1.z.array(zod_1.z.number().positive()),
            order: zod_1.z.number().positive(),
            picklistIds: zod_1.z.array(zod_1.z.string()),
            picklists: zod_1.z
                .array(zod_1.z.object({
                picklistId: zod_1.z.string().min(1),
                partitionNumber: zod_1.z.number().positive(),
                quantity: zod_1.z.number().positive(),
            }))
                .optional(),
        })),
        bins: zod_1.z.array(zod_1.z.object({
            id: zod_1.z.string().min(1),
            partitions: zod_1.z.array(zod_1.z.object({
                partitionNumber: zod_1.z.number().positive(),
                quantityAllocatedIncrease: zod_1.z.number().positive(),
            })),
        })),
    }),
});
exports.taskGroupAutostoreSyncSucceededSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TASK_GROUP_AUTOSTORE_SYNC_SUCCEEDED'),
    payload: zod_1.z.object({
        taskGroupId: zod_1.z.string().min(1),
        taskGroupAutostoreId: zod_1.z.number().positive(),
        tasks: zod_1.z.array(zod_1.z.object({
            id: zod_1.z.string().min(1),
            autostoreId: zod_1.z.number().positive(),
        })),
    }),
});
exports.taskGroupAutostoreSyncFailedEventSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TASK_GROUP_AUTOSTORE_SYNC_FAILED'),
    payload: zod_1.z.object({
        taskGroupId: zod_1.z.string().min(1),
        reason: zod_1.z.union([
            zod_1.z.literal('INVALID_TASK_WITHOUT_BIN_FOUND'),
            zod_1.z.literal('TASK_GROUP_WITH_BINS_NOT_FOUND'),
            zod_1.z.literal('TASK_WITH_BIN_NOT_FOUND'),
            zod_1.z.literal('INVALID_TASK_GROUP_STATUS'),
            zod_1.z.literal('TASK_GROUP_NOT_FOUND'),
            zod_1.z.literal('UNHANDLED_AUTOSTORE_MANAGER_ERROR'),
            ...error_1.addTaskErrorOutcomes.map((error) => zod_1.z.literal(error)),
            ...error_1.createTaskgroupErrorOutcomes.map((error) => zod_1.z.literal(error)),
        ]),
    }),
});
exports.TaskGroupCompleted = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TASK_GROUP_COMPLETED'),
    payload: zod_1.z.object({
        taskGroupId: zod_1.z.string().min(1),
    }),
});
exports.taskGroupBinsUnallocatedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TASK_GROUP_BINS_UNALLOCATED'),
    payload: zod_1.z.object({
        taskGroupId: zod_1.z.string().min(1),
        bins: zod_1.z.array(zod_1.z.object({
            id: zod_1.z.string().min(1),
            autostoreBinId: zod_1.z.number().optional(),
            partitions: zod_1.z.array(zod_1.z.object({
                partitionNumber: zod_1.z.number().positive(),
                quantityAllocatedDecrease: zod_1.z.number().positive(),
            })),
        })),
    }),
});
exports.TaskGroupTaskCancelled = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TASK_GROUP_TASK_CANCELLED'),
    payload: zod_1.z.object({
        taskGroupId: zod_1.z.string().min(1),
        autostoreTaskGroupId: zod_1.z.number().positive().optional(),
        taskId: zod_1.z.string().min(1),
        autostoreTaskId: zod_1.z.number().positive().optional(),
    }),
});
exports.TaskPartitionPicksCancelled = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TASK_PARTITION_PICKS_CANCELLED'),
    payload: zod_1.z.object({
        taskGroupId: zod_1.z.string().min(1),
        autostoreTaskGroupId: zod_1.z.number().positive(),
        taskId: zod_1.z.string().min(1),
        autostoreTaskId: zod_1.z.number().positive(),
        autostoreBinId: zod_1.z.number().positive(),
        partitionNumber: zod_1.z.number().positive(),
        affectedPicklistIds: zod_1.z.array(zod_1.z.string().min(1)), // Included for analytics purposes.
    }),
});
exports.taskGroupCancelledSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TASK_GROUP_CANCELLED'),
    payload: zod_1.z.object({
        taskGroupId: zod_1.z.string().min(1),
        picklistIds: zod_1.z.array(zod_1.z.string().min(1)).optional(),
        fulfilmentOrderIds: zod_1.z.array(zod_1.z.string().min(1)).optional(),
        bins: zod_1.z
            .array(zod_1.z.object({
            id: zod_1.z.string().min(1),
            autostoreBinId: zod_1.z.number().optional(),
            partitions: zod_1.z.array(zod_1.z.object({
                partitionNumber: zod_1.z.number().positive(),
                quantityAllocatedDecrease: zod_1.z.number().positive(),
                unallocatedPicklistIds: zod_1.z.string().array().optional(),
            })),
        }))
            .optional(),
    }),
});
exports.autostoreSyncStartedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_SYNC_STARTED'),
    payload: zod_1.z.object({
        taskGroupIds: zod_1.z.array(zod_1.z.string().min(1)),
    }),
});
exports.autostoreSyncFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_SYNC_FAILED'),
    payload: zod_1.z.object({
        reason: zod_1.z.string(),
    }),
});
exports.picklistsUnassignedFromTaskGroupSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICKLISTS_UNASSIGNED_FROM_TASK_GROUP'),
    payload: zod_1.z.object({
        picklistIds: zod_1.z.array(zod_1.z.string().min(1)),
        fulfilmentOrderIds: zod_1.z.array(zod_1.z.string().min(1)),
        taskGroupId: zod_1.z.string().min(1),
    }),
});
exports.taskGroupSuspendedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TASK_GROUP_SUSPENDED'),
    payload: zod_1.z.object({
        taskGroupId: zod_1.z.string(),
    }),
});
exports.taskGroupUnsuspendedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TASK_GROUP_UNSUSPENDED'),
    payload: zod_1.z.object({
        taskGroupId: zod_1.z.string(),
    }),
});
exports.suspendTaskGroupFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('SUSPEND_TASK_GROUP_FAILED'),
    payload: zod_1.z.object({
        taskGroupId: zod_1.z.string(),
        reason: zod_1.z.discriminatedUnion('outcome', [
            zod_1.z.object({ outcome: zod_1.z.literal('NO_AUTOSTORE') }),
            zod_1.z.object({ outcome: zod_1.z.literal('NO_TASK_GROUP') }),
            zod_1.z.object({ outcome: zod_1.z.literal('NO_AUTOSTORE_TASK_GROUP') }),
            zod_1.z.object({
                outcome: zod_1.z.literal('INVALID_TASK_GROUP_STATUS'),
                expectedStatuses: taskGroupStatusSchema.array(),
                currentStatus: taskGroupStatusSchema,
            }),
            zod_1.z.object({ outcome: zod_1.z.literal('AUTOSTORE_ERROR'), error: zod_1.z.string() }),
        ]),
    }),
});
exports.unsuspendTaskGroupFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('UNSUSPEND_TASK_GROUP_FAILED'),
    payload: zod_1.z.object({
        taskGroupId: zod_1.z.string(),
        reason: zod_1.z.discriminatedUnion('outcome', [
            zod_1.z.object({ outcome: zod_1.z.literal('NO_AUTOSTORE') }),
            zod_1.z.object({ outcome: zod_1.z.literal('NO_TASK_GROUP') }),
            zod_1.z.object({ outcome: zod_1.z.literal('NO_AUTOSTORE_TASK_GROUP') }),
            zod_1.z.object({
                outcome: zod_1.z.literal('INVALID_TASK_GROUP_STATUS'),
                expectedStatuses: taskGroupStatusSchema.array(),
                currentStatus: taskGroupStatusSchema,
            }),
            zod_1.z.object({ outcome: zod_1.z.literal('AUTOSTORE_ERROR'), error: zod_1.z.string() }),
        ]),
    }),
});
exports.TaskGroupEvent = zod_1.z.union([
    exports.taskGroupsCreatedSchema,
    exports.taskGroupBinsAllocatedSchema,
    exports.taskGroupAutostoreSyncSucceededSchema,
    exports.TaskGroupCompleted,
    exports.taskGroupBinsUnallocatedSchema,
    exports.taskGroupCancelledSchema,
    exports.TaskGroupTaskCancelled,
    exports.TaskPartitionPicksCancelled,
    exports.picklistsUnassignedFromTaskGroupSchema,
    exports.autostoreSyncStartedSchema,
    exports.autostoreSyncFailedSchema,
    exports.taskGroupAutostoreSyncFailedEventSchema,
    exports.taskGroupSuspendedSchema,
    exports.suspendTaskGroupFailedSchema,
    exports.taskGroupUnsuspendedSchema,
    exports.unsuspendTaskGroupFailedSchema,
]);
