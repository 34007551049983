import { PortBinStateType, Transfer } from 'api-schema/lib/model';
import { TransferDetailsView } from './TransferDetails.view';

type Props = {
  transfer: Transfer;
  disabled: boolean;
  onCompletePutaway: () => void;
  targetTransferBarcode?: string;
  currentBin?: PortBinStateType;
};

export const TransferDetails = ({
  transfer,
  disabled,
  onCompletePutaway,
  targetTransferBarcode,
  currentBin,
}: Props) => {
  return (
    <TransferDetailsView
      transfer={transfer}
      onCompletePutaway={onCompletePutaway}
      disabled={disabled}
      targetTransferBarcode={targetTransferBarcode}
      currentBin={currentBin}
    />
  );
};
