"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unhandledAutostoreManagerError = exports.getBinStateErrorOutcome = exports.getBinStateErrorOutcomes = exports.getSystemStateErrorOutcome = exports.getSystemStateErrorOutcomes = exports.cancelTaskErrorOutcome = exports.cancelTaskErrorOutcomes = exports.addTaskErrorOutcome = exports.addTaskErrorOutcomes = exports.getNextTaskgroupsErrorOutcome = exports.getNextTaskgroupsErrorOutcomes = exports.updateTaskgroupErrorOutcome = exports.updateTaskgroupErrorOutcomes = exports.cancelTaskgroupErrorOutcome = exports.cancelTaskgroupErrorOutcomes = exports.closeBinErrorOutcome = exports.closeBinErrorOutcomes = exports.getPortStatusErrorOutcome = exports.getPortStatusErrorOutcomes = exports.getAllPortsErrorOutcome = exports.getAllPortsErrorOutcomes = exports.updateBinErrorOutcome = exports.updateBinErrorOutcomes = exports.openBinErrorOutcome = exports.openBinErrorOutcomes = exports.closePortErrorOutcome = exports.closePortErrorOutcomes = exports.openPortErrorOutcome = exports.openPortErrorOutcomes = exports.createTaskgroupErrorOutcome = exports.createTaskgroupErrorOutcomes = exports.getAllBinsErrorOutcome = exports.getAllBinsErrorOutcomes = exports.getBinErrorOutcome = exports.getBinsErrorOutcomes = exports.ASErrorOutcomeMap = void 0;
const zod_1 = require("zod");
exports.ASErrorOutcomeMap = {
    '1000': '1000_PORT_NOT_AVAILABLE',
    '1001': '1001_PORT_IS_CLOSING',
    '1002': '1002_PORT_ALREADY_OPEN',
    '1003': '1003_PORT_ID_MISSING',
    '1004': '1004_PORT_ID_NOT_VALID',
    '1006': '1006_CONTENT_NOT_VALID',
    '1007': '1007_CATEGORY_NOT_VALID',
    '1008': '1008_REQ_TIME_NOT_VALID',
    '1009': '1009_PORT_NOT_OPEN',
    '1010': '1010_BIN_ID_NOT_VALID',
    '1011': '1011_BIN_DOES_NOT_EXIST',
    '1012': '1012_TASK_ID_NOT_VALID',
    '1013': '1013_TASK_GROUP_ID_NOT_VALID',
    '1014': '1014_SHIPMENT_NOT_VALID',
    '1015': '1015_NO_PORT_OR_BINS_THAT_FULFIL_PORT_SELECT_REQUIREMENTS',
    '1016': '1016_NO_BINS_READY_THAT_FULFIL_PORT_SELECT_REQUIREMENTS',
    '1017': '1017_TASK_DOES_NOT_EXIST',
    '1018': '1018_TASK_GROUP_DOES_NOT_EXIST',
    '1019': '1019_TASK_GROUP_SELECTED_BY_ANOTHER_PORT',
    '1020': '1020_OPENBIN_CONFLICTS_WITH_PREVIOUS_OPENBIN_CALL_THAT_IS_NOT_CLOSED_YET',
    '1021': '1021_OPENBIN_CONFLICTS_WITH_PORTS_SELECTED_SHIPMENT',
    '1022': '1022_OPENBIN_CONFLICTS_WITH_PORTS_SELECTED_TASK_GROUP',
    '1023': '1023_MULTIPLE_SELECTION_PARAMETERS_NOT_ALLOWED',
    '1024': '1024_BIN_ID_MISSING',
    '1025': '1025_TASKCOMPLETE_NOT_VALID',
    '1026': '1026_PORT_DOES_NOT_HAVE_SELECTED_BIN',
    '1027': '1027_BIN_NOT_READY_IN_PORT',
    '1028': '1028_BIN_AND_TASK_PARAMETERS_CONFLICT_WITH_OPEN_BIN_AND_TASK',
    '1031': '1031_TASK_GROUP_ID_MISSING',
    '1032': '1032_TASK_GROUP_ALREADY_EXISTS',
    '1033': '1033_TASK_ALREADY_EXISTS',
    '1034': '1034_CATEGORY_IS_MISSING',
    '1035': '1035_REQ_TIME_IS_MISSING',
    '1036': '1036_PRIORITY_IS_MISSING',
    '1037': '1037_PRIORITY_NOT_VALID',
    '1038': '1038_START_TIME_NOT_VALID',
    '1039': '1039_SUSPENDED_NOT_VALID',
    '1040': '1040_TASK_ID_MISSING',
    '1041': '1041_ITEM_NOT_VALID',
    '1042': '1042_ORDER_NOT_VALID',
    '1043': '1043_TASK_GROUP_HAS_NO_TASKS',
    '1044': '1044_TASKGROUP_SELECTED_BY_A_PORT',
    '1045': '1045_TASK_SELECTED_BY_A_PORT',
    '1048': '1048_SYSTEM_NOT_AVAILABLE',
    '1049': '1049_RESPONSE_OVERFLOW',
    '1050': '1050_TOO_MANY_CATEGORIES_OR_CONTENT_CODES',
    '1055': '1055_MIN_BIN_ID_NOT_VALID',
    '1056': '1056_MAX_BIN_ID_NOT_VALID',
    '1057': '1057_ESTPICKTIME_NOT_VALID',
};
exports.getBinsErrorOutcomes = [
    exports.ASErrorOutcomeMap['1010'],
    exports.ASErrorOutcomeMap['1011'],
    exports.ASErrorOutcomeMap['1049'],
];
exports.getBinErrorOutcome = zod_1.z.enum(exports.getBinsErrorOutcomes);
exports.getAllBinsErrorOutcomes = [
    exports.ASErrorOutcomeMap['1010'],
    exports.ASErrorOutcomeMap['1011'],
    exports.ASErrorOutcomeMap['1049'],
];
exports.getAllBinsErrorOutcome = zod_1.z.enum(exports.getAllBinsErrorOutcomes);
exports.createTaskgroupErrorOutcomes = [
    exports.ASErrorOutcomeMap['1007'],
    exports.ASErrorOutcomeMap['1008'],
    exports.ASErrorOutcomeMap['1010'],
    exports.ASErrorOutcomeMap['1011'],
    exports.ASErrorOutcomeMap['1012'],
    exports.ASErrorOutcomeMap['1013'],
    exports.ASErrorOutcomeMap['1014'],
    exports.ASErrorOutcomeMap['1024'],
    exports.ASErrorOutcomeMap['1025'],
    exports.ASErrorOutcomeMap['1031'],
    exports.ASErrorOutcomeMap['1032'],
    exports.ASErrorOutcomeMap['1033'],
    exports.ASErrorOutcomeMap['1034'],
    exports.ASErrorOutcomeMap['1035'],
    exports.ASErrorOutcomeMap['1036'],
    exports.ASErrorOutcomeMap['1037'],
    exports.ASErrorOutcomeMap['1038'],
    exports.ASErrorOutcomeMap['1039'],
    exports.ASErrorOutcomeMap['1040'],
    exports.ASErrorOutcomeMap['1041'],
    exports.ASErrorOutcomeMap['1042'],
    exports.ASErrorOutcomeMap['1043'],
    exports.ASErrorOutcomeMap['1057'],
];
exports.createTaskgroupErrorOutcome = zod_1.z.enum(exports.createTaskgroupErrorOutcomes);
exports.openPortErrorOutcomes = [
    exports.ASErrorOutcomeMap['1000'],
    exports.ASErrorOutcomeMap['1001'],
    exports.ASErrorOutcomeMap['1002'],
    exports.ASErrorOutcomeMap['1003'],
    exports.ASErrorOutcomeMap['1004'],
    exports.ASErrorOutcomeMap['1006'],
    exports.ASErrorOutcomeMap['1007'],
    exports.ASErrorOutcomeMap['1008'],
    exports.ASErrorOutcomeMap['1048'],
    exports.ASErrorOutcomeMap['1050'],
];
exports.openPortErrorOutcome = zod_1.z.enum(exports.openPortErrorOutcomes);
exports.closePortErrorOutcomes = [
    exports.ASErrorOutcomeMap['1000'],
    exports.ASErrorOutcomeMap['1003'],
    exports.ASErrorOutcomeMap['1004'],
    exports.ASErrorOutcomeMap['1048'],
];
exports.closePortErrorOutcome = zod_1.z.enum(exports.closePortErrorOutcomes);
exports.openBinErrorOutcomes = [
    exports.ASErrorOutcomeMap['1000'],
    exports.ASErrorOutcomeMap['1003'],
    exports.ASErrorOutcomeMap['1009'],
    exports.ASErrorOutcomeMap['1010'],
    exports.ASErrorOutcomeMap['1011'],
    exports.ASErrorOutcomeMap['1012'],
    exports.ASErrorOutcomeMap['1013'],
    exports.ASErrorOutcomeMap['1014'],
    exports.ASErrorOutcomeMap['1015'],
    exports.ASErrorOutcomeMap['1016'],
    exports.ASErrorOutcomeMap['1017'],
    exports.ASErrorOutcomeMap['1018'],
    exports.ASErrorOutcomeMap['1019'],
    exports.ASErrorOutcomeMap['1020'],
    exports.ASErrorOutcomeMap['1021'],
    exports.ASErrorOutcomeMap['1022'],
    exports.ASErrorOutcomeMap['1023'],
    exports.ASErrorOutcomeMap['1048'],
];
exports.openBinErrorOutcome = zod_1.z.enum(exports.openBinErrorOutcomes);
exports.updateBinErrorOutcomes = [
    exports.ASErrorOutcomeMap['1006'],
    exports.ASErrorOutcomeMap['1010'],
    exports.ASErrorOutcomeMap['1011'],
    exports.ASErrorOutcomeMap['1024'],
];
exports.updateBinErrorOutcome = zod_1.z.enum(exports.updateBinErrorOutcomes);
exports.getAllPortsErrorOutcomes = [
    exports.ASErrorOutcomeMap['1000'],
    exports.ASErrorOutcomeMap['1003'],
    exports.ASErrorOutcomeMap['1004'],
];
exports.getAllPortsErrorOutcome = zod_1.z.enum(exports.getAllPortsErrorOutcomes);
exports.getPortStatusErrorOutcomes = [
    exports.ASErrorOutcomeMap['1000'],
    exports.ASErrorOutcomeMap['1003'],
    exports.ASErrorOutcomeMap['1004'],
];
exports.getPortStatusErrorOutcome = zod_1.z.enum(exports.getPortStatusErrorOutcomes);
exports.closeBinErrorOutcomes = [
    exports.ASErrorOutcomeMap['1000'],
    exports.ASErrorOutcomeMap['1003'],
    exports.ASErrorOutcomeMap['1004'],
    exports.ASErrorOutcomeMap['1006'],
    exports.ASErrorOutcomeMap['1010'],
    exports.ASErrorOutcomeMap['1012'],
    exports.ASErrorOutcomeMap['1017'],
    exports.ASErrorOutcomeMap['1024'],
    exports.ASErrorOutcomeMap['1025'],
    exports.ASErrorOutcomeMap['1026'],
    exports.ASErrorOutcomeMap['1027'],
    exports.ASErrorOutcomeMap['1028'],
    exports.ASErrorOutcomeMap['1048'],
];
exports.closeBinErrorOutcome = zod_1.z.enum(exports.closeBinErrorOutcomes);
exports.cancelTaskgroupErrorOutcomes = [
    exports.ASErrorOutcomeMap['1013'],
    exports.ASErrorOutcomeMap['1018'],
    exports.ASErrorOutcomeMap['1031'],
    exports.ASErrorOutcomeMap['1044'],
];
exports.cancelTaskgroupErrorOutcome = zod_1.z.enum(exports.cancelTaskgroupErrorOutcomes);
exports.updateTaskgroupErrorOutcomes = [
    exports.ASErrorOutcomeMap['1007'],
    exports.ASErrorOutcomeMap['1008'],
    exports.ASErrorOutcomeMap['1013'],
    exports.ASErrorOutcomeMap['1014'],
    exports.ASErrorOutcomeMap['1018'],
    exports.ASErrorOutcomeMap['1031'],
    exports.ASErrorOutcomeMap['1034'],
    exports.ASErrorOutcomeMap['1035'],
    exports.ASErrorOutcomeMap['1036'],
    exports.ASErrorOutcomeMap['1037'],
    exports.ASErrorOutcomeMap['1038'],
    exports.ASErrorOutcomeMap['1039'],
    exports.ASErrorOutcomeMap['1044'],
];
exports.updateTaskgroupErrorOutcome = zod_1.z.enum(exports.updateTaskgroupErrorOutcomes);
exports.getNextTaskgroupsErrorOutcomes = [
    exports.ASErrorOutcomeMap['1003'],
    exports.ASErrorOutcomeMap['1004'],
];
exports.getNextTaskgroupsErrorOutcome = zod_1.z.enum(exports.getNextTaskgroupsErrorOutcomes);
exports.addTaskErrorOutcomes = [
    exports.ASErrorOutcomeMap['1010'],
    exports.ASErrorOutcomeMap['1011'],
    exports.ASErrorOutcomeMap['1012'],
    exports.ASErrorOutcomeMap['1013'],
    exports.ASErrorOutcomeMap['1018'],
    exports.ASErrorOutcomeMap['1024'],
    exports.ASErrorOutcomeMap['1031'],
    exports.ASErrorOutcomeMap['1033'],
    exports.ASErrorOutcomeMap['1040'],
    exports.ASErrorOutcomeMap['1041'],
    exports.ASErrorOutcomeMap['1042'],
    exports.ASErrorOutcomeMap['1057'],
];
exports.addTaskErrorOutcome = zod_1.z.enum(exports.addTaskErrorOutcomes);
exports.cancelTaskErrorOutcomes = [
    exports.ASErrorOutcomeMap['1012'],
    exports.ASErrorOutcomeMap['1017'],
    exports.ASErrorOutcomeMap['1040'],
    exports.ASErrorOutcomeMap['1045'],
];
exports.cancelTaskErrorOutcome = zod_1.z.enum(exports.cancelTaskErrorOutcomes);
exports.getSystemStateErrorOutcomes = [];
exports.getSystemStateErrorOutcome = zod_1.z.never();
exports.getBinStateErrorOutcomes = [
    exports.ASErrorOutcomeMap['1010'],
    exports.ASErrorOutcomeMap['1011'],
    exports.ASErrorOutcomeMap['1049'],
    exports.ASErrorOutcomeMap['1055'],
    exports.ASErrorOutcomeMap['1056'],
];
exports.getBinStateErrorOutcome = zod_1.z.enum(exports.getBinStateErrorOutcomes);
exports.unhandledAutostoreManagerError = zod_1.z.literal('UNHANDLED_AUTOSTORE_MANAGER_ERROR');
