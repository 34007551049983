import {
  Archive,
  Dashboard,
  DeviceHub,
  Home,
  Storage,
  SupervisedUserCircle,
} from '@material-ui/icons';
import { NavigationLink } from '../components/admin/Navigation/Navigation';
import {
  DEVICE_PATHS,
  DEV_PATHS,
  MAIN_PATHS,
  PORT_PATHS,
  WAREHOUSE_PATHS,
} from './paths';

export const mainLinks: NavigationLink[] = [
  {
    label: 'Home',
    icon: Home,
    path: MAIN_PATHS.ADMIN_HOME,
  },
];

export const warehouseLinks: NavigationLink[] = [
  {
    label: 'Overview',
    icon: Dashboard,
    path: WAREHOUSE_PATHS.OVERVIEW,
  },
  {
    label: 'Storage units',
    icon: Storage,
    path: WAREHOUSE_PATHS.STORAGE_UNITS,
  },
  {
    label: 'Devices',
    icon: DeviceHub,
    path: DEVICE_PATHS.OVERVIEW,
  },
  {
    label: 'Ports',
    icon: Archive,
    path: PORT_PATHS.OVERVIEW,
  },
];

export const devLinks: NavigationLink[] = [
  {
    label: 'Authentication',
    icon: SupervisedUserCircle,
    path: DEV_PATHS.AUTH,
  },
];
