"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PutawayEvent = exports.selectReplenishmentModeFailedSchema = exports.replenishmentModeSelectedSchema = exports.binEmptinessCheckFailedSchema = exports.putawayBinRequestFailedSchema = exports.warehouseWithNoAutostoreOutcomeSchema = exports.warehouseNotFoundOutcomeSchema = exports.unhandledAutostoreManagerOutcomeSchema = exports.noMatchingBinsReadyOutcome = exports.noMatchingBinsOutcome = exports.putawayBinRequestedSchema = exports.binEmptinessCheckedSchema = exports.closePutawayPortFailedSchema = exports.putawayPortClosedSchema = exports.completePutawayFailedSchema = exports.configurePartitionsFailedSchema = exports.partitionsConfiguredSchema = exports.returnPutawayBinFailedSchema = exports.putawayBinReturnedSchema = exports.putawayItemRemovedSchema = exports.putawayItemAddedSchema = exports.undoBarcodeScanAtPutawayFailedSchema = exports.barcodeScanUndoneAtPutawaySchema = exports.scanBarcodeAtPutawayFailedSchema = exports.barcodeScannedAtPutawaySchema = exports.putawayCompletedSchema = exports.putawayPortOpenedSchema = void 0;
const zod_1 = require("zod");
const model_1 = require("../model");
const base_1 = require("./base");
const putawayStatusSchema = zod_1.z.enum([
    'OPEN',
    'CLOSED',
    'AWAITING_BIN',
    'READY', // Port "open" but no bin requested
]);
const portTypeSchema = zod_1.z.enum(['UNCONFIGURED', 'PICK', 'PUTAWAY']);
exports.putawayPortOpenedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PUTAWAY_PORT_OPENED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        contentCodes: zod_1.z.number().int().gte(0).array(),
    }),
});
const PutawayPortOpenFailed = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('OPEN_PUTAWAY_PORT_FAILED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
    }),
});
exports.putawayCompletedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PUTAWAY_COMPLETED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        transferId: base_1.StringId.optional(),
        itemsMissing: zod_1.z
            .object({
            retailUnitKey: model_1.retailUnitRecordKeySchema,
            quantityMissing: zod_1.z.number().int().positive(),
            quantityAllocatedForVirtualTransfer: zod_1.z
                .object({
                binId: base_1.StringId,
                partitionNumber: zod_1.z.number().int().positive(),
                previous: zod_1.z.number().int().nonnegative(),
                delta: zod_1.z.number().int().negative(),
                current: zod_1.z.number().int().nonnegative(),
            })
                .optional(),
        })
            .array()
            .optional(),
        otherActivePorts: zod_1.z.number().int().array().optional(),
    }),
});
exports.barcodeScannedAtPutawaySchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('BARCODE_SCANNED_AT_PUTAWAY'),
    portId: base_1.portIdSchema,
    transferId: base_1.StringId,
    organisationId: base_1.StringId,
    payload: zod_1.z.object({
        transferId: base_1.StringId,
        portId: base_1.portIdSchema,
        autostoreBinId: base_1.autostoreBinIdSchema,
        binId: base_1.StringId,
        partitionNumber: zod_1.z.number().int().nonnegative(),
        retailUnitKey: model_1.retailUnitRecordKeySchema,
        barcode: base_1.StringId,
        quantityPutaway: zod_1.z.object({
            current: zod_1.z.number().positive(),
            delta: zod_1.z.literal(1),
            previous: zod_1.z.number().nonnegative().optional(),
        }),
    }),
});
exports.scanBarcodeAtPutawayFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('SCAN_BARCODE_AT_PUTAWAY_FAILED'),
    portId: base_1.portIdSchema,
    payload: zod_1.z.object({
        reason: zod_1.z.discriminatedUnion('outcome', [
            zod_1.z.object({ outcome: zod_1.z.literal('RETAIL_UNIT_NOT_FOUND') }),
            zod_1.z.object({ outcome: zod_1.z.literal('PARTITION_NOT_FOUND') }),
            zod_1.z.object({
                outcome: zod_1.z.literal('UNEXPECTED_RETAIL_UNIT_IN_PARTITION'),
                currentRetailUnitKey: model_1.retailUnitRecordKeySchema,
            }),
            zod_1.z.object({ outcome: zod_1.z.literal('TRANSFER_DOES_NOT_CONTAIN_RETAIL_UNIT') }),
            zod_1.z.object({ outcome: zod_1.z.literal('NO_CURRENT_BIN') }),
            zod_1.z.object({ outcome: zod_1.z.literal('PORT_NOT_FOUND') }),
            zod_1.z.object({ outcome: zod_1.z.literal('NO_CURRENT_TRANSFER') }),
        ]),
        transferId: base_1.StringId.optional(),
        portId: base_1.portIdSchema,
        partitionNumber: zod_1.z.number().int().nonnegative(),
        barcode: zod_1.z.string().min(1),
    }),
});
exports.barcodeScanUndoneAtPutawaySchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('BARCODE_SCAN_UNDONE_AT_PUTAWAY'),
    portId: base_1.portIdSchema,
    transferId: base_1.StringId,
    organisationId: base_1.StringId,
    payload: zod_1.z.object({
        transferId: base_1.StringId,
        portId: base_1.portIdSchema,
        autostoreBinId: base_1.autostoreBinIdSchema,
        binId: base_1.StringId,
        partitionNumber: zod_1.z.number().int().nonnegative(),
        retailUnitKey: model_1.retailUnitRecordKeySchema,
        barcode: base_1.StringId,
        quantityPutaway: zod_1.z.object({
            current: zod_1.z.number().nonnegative(),
            delta: zod_1.z.literal(-1),
            previous: zod_1.z.number().positive(),
        }),
    }),
});
exports.undoBarcodeScanAtPutawayFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('UNDO_BARCODE_SCAN_AT_PUTAWAY_FAILED'),
    portId: base_1.portIdSchema,
    payload: zod_1.z.object({
        reason: zod_1.z.discriminatedUnion('outcome', [
            zod_1.z.object({ outcome: zod_1.z.literal('RETAIL_UNIT_NOT_FOUND') }),
            zod_1.z.object({ outcome: zod_1.z.literal('PARTITION_NOT_FOUND') }),
            zod_1.z.object({
                outcome: zod_1.z.literal('UNEXPECTED_RETAIL_UNIT_IN_PARTITION'),
                currentRetailUnitKey: model_1.retailUnitRecordKeySchema,
            }),
            zod_1.z.object({ outcome: zod_1.z.literal('TRANSFER_DOES_NOT_CONTAIN_RETAIL_UNIT') }),
            zod_1.z.object({
                outcome: zod_1.z.literal('INSUFFICIENT_STOCK_TO_REMOVE'),
            }),
            zod_1.z.object({ outcome: zod_1.z.literal('NO_CURRENT_BIN') }),
            zod_1.z.object({ outcome: zod_1.z.literal('PORT_NOT_FOUND') }),
            zod_1.z.object({ outcome: zod_1.z.literal('NO_CURRENT_TRANSFER') }),
            zod_1.z.object({ outcome: zod_1.z.literal('TARGET_PARTITION_EMPTY') }),
        ]),
        transferId: base_1.StringId.optional(),
        portId: base_1.portIdSchema,
        partitionNumber: zod_1.z.number().int().nonnegative(),
        barcode: base_1.StringId,
    }),
});
exports.putawayItemAddedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PUTAWAY_ITEM_ADDED'),
    payload: zod_1.z.object({
        transferId: base_1.StringId,
        portId: base_1.portIdSchema,
        autostoreBinId: base_1.autostoreBinIdSchema,
        binId: zod_1.z.string().min(1).optional(),
        partitionNumber: zod_1.z.number().int().nonnegative(),
        retailUnitBarcode: zod_1.z.string().min(1),
    }),
});
const addPutawayItemFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('ADD_PUTAWAY_ITEM_FAILED'),
    payload: zod_1.z.object({
        reason: zod_1.z.union([
            zod_1.z.literal('NO_BARCODE_RETAIL_UNIT_MATCH'),
            zod_1.z.literal('INVALID_TARGET_PARTITION'),
            zod_1.z.literal('NO_CURRENT_BIN'),
            zod_1.z.literal('INVALID_PORT_TYPE'),
            zod_1.z.literal('PORT_NOT_FOUND'),
            zod_1.z.literal('NO_CURRENT_TRANSFER'),
        ]),
        transferId: base_1.StringId,
        portId: base_1.portIdSchema,
        partitionNumber: zod_1.z.number().int().nonnegative(),
        retailUnitBarcode: zod_1.z.string().min(1),
    }),
});
exports.putawayItemRemovedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PUTAWAY_ITEM_REMOVED'),
    payload: zod_1.z.object({
        transferId: base_1.StringId,
        portId: base_1.portIdSchema,
        autostoreBinId: base_1.autostoreBinIdSchema,
        binId: zod_1.z.string().min(1).optional(),
        partitionNumber: zod_1.z.number().int().nonnegative(),
        retailUnitBarcode: zod_1.z.string().min(1),
    }),
});
const removePutawayItemFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PUTAWAY_REMOVE_ITEM_FAILED'),
    payload: zod_1.z.object({
        reason: zod_1.z.union([
            zod_1.z.literal('NO_BARCODE_RETAIL_UNIT_MATCH'),
            zod_1.z.literal('INVALID_TARGET_PARTITION'),
            zod_1.z.literal('NO_CURRENT_BIN'),
            zod_1.z.literal('INVALID_PORT_TYPE'),
            zod_1.z.literal('PORT_NOT_FOUND'),
            zod_1.z.literal('NO_CURRENT_TRANSFER'),
            zod_1.z.literal('TARGET_PARTITION_EMPTY'),
        ]),
        transferId: base_1.StringId,
        portId: base_1.portIdSchema,
        partitionNumber: zod_1.z.number().int().nonnegative(),
        retailUnitBarcode: zod_1.z.string().nonempty(),
    }),
});
exports.putawayBinReturnedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PUTAWAY_BIN_RETURNED'),
    version: zod_1.z.literal(2).default(2),
    payload: zod_1.z.object({
        autostoreBinId: base_1.autostoreBinIdSchema,
        binId: zod_1.z.string().min(1).optional(),
        portId: base_1.portIdSchema,
        // This prop is added since WMS-458, which will be handled in reducer/transfer
        transferId: base_1.StringId.optional(),
        putawayMode: model_1.putawayModeSchema.optional(),
        itemsPutaway: zod_1.z
            .object({
            retailUnitKey: model_1.retailUnitRecordKeySchema,
            quantityPutaway: zod_1.z.number().int().positive(),
            partitionNumber: zod_1.z.number().int().positive(),
            transferItemQuantityPutaway: zod_1.z.object({
                previous: zod_1.z.number().int().nonnegative(),
                delta: zod_1.z.number().int().positive(),
                current: zod_1.z.number().int().positive(),
            }),
            transferItemIncomingQuantity: zod_1.z.object({
                previous: zod_1.z.number().int().nonnegative(),
                delta: zod_1.z.number().int().nonpositive(),
                current: zod_1.z.number().int().nonnegative(),
            }),
            virtualBinQuantityRemoved: zod_1.z
                .object({
                binId: base_1.StringId,
                partitionNumber: zod_1.z.number().int(),
                quantity: zod_1.z.object({
                    current: zod_1.z.number().int().nonnegative(),
                    delta: zod_1.z.number().int().negative(),
                    previous: zod_1.z.number().int().positive(),
                }),
                quantityAllocatedForVirtualTransfer: zod_1.z.object({
                    current: zod_1.z.number().int().nonnegative(),
                    delta: zod_1.z.number().int().negative(),
                    previous: zod_1.z.number().int().positive(),
                }),
            })
                .optional(),
        })
            .array()
            .optional(),
    }),
});
exports.returnPutawayBinFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('RETURN_PUTAWAY_BIN_FAILED'),
    payload: zod_1.z.discriminatedUnion('outcome', [
        zod_1.z.object({
            outcome: zod_1.z.literal('WAREHOUSE_NOT_FOUND'),
            warehouseId: zod_1.z.string(),
            portId: base_1.portIdSchema,
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('WAREHOUSE_WITH_AUTOSTORE_NOT_FOUND'),
            warehouseId: zod_1.z.string(),
            portId: base_1.portIdSchema,
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('PORT_NOT_FOUND'),
            warehouseId: zod_1.z.string(),
            portId: zod_1.z.number(),
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('INVALID_PORT_TYPE'),
            warehouseId: zod_1.z.string(),
            portId: zod_1.z.number(),
            actualPortType: portTypeSchema,
            expectedPortType: zod_1.z.literal('PUTAWAY'),
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('INVALID_PORT_STATUS'),
            warehouseId: zod_1.z.string(),
            portId: zod_1.z.number(),
            currentPortStatus: model_1.putawayPortStatusSchema,
            expectedPortStatus: zod_1.z.literal('OPEN'),
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('NO_CURRENT_BIN'),
            warehouseId: zod_1.z.string(),
            portId: zod_1.z.number(),
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('NO_CURRENT_TRANSFER'),
            warehouseId: zod_1.z.string(),
            portId: zod_1.z.number(),
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('PUTAWAY_MODE_NOT_FOUND'),
            warehouseId: zod_1.z.string(),
            portId: zod_1.z.number(),
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('AUTOSTORE_ERROR'),
            autostoreError: zod_1.z.string(),
            portId: base_1.portIdSchema,
            binId: zod_1.z.string(),
            autostoreBinId: zod_1.z.number(),
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('INTERNAL_TRANSFER_HAS_NO_VIRTUAL_BIN'),
            expectedVirtualBinId: zod_1.z.string(),
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('VIRTUAL_PARTITIONS_MISSING'),
            missingRetailUnits: model_1.retailUnitRecordKeySchema.array(),
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('INSUFFICIENT_STOCK_IN_VIRTUAL_PARTITION'),
            insufficientStock: zod_1.z
                .object({
                retailUnitKey: model_1.retailUnitRecordKeySchema,
                virtualPartitionNumber: zod_1.z.number().int(),
                quantityRequired: zod_1.z.number().int(),
                quantityMissing: zod_1.z.number().int(),
            })
                .array(),
        }),
    ]),
});
exports.partitionsConfiguredSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PARTITIONS_CONFIGURED'),
    payload: zod_1.z.object({
        autostoreBinId: base_1.autostoreBinIdSchema,
        binId: zod_1.z.string().min(1),
        portId: base_1.portIdSchema,
        numberOfPartitions: zod_1.z.number().int().positive(),
    }),
});
exports.configurePartitionsFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CONFIGURE_PARTITIONS_FAILED'),
    payload: zod_1.z.object({
        reason: zod_1.z.union([
            zod_1.z.literal('PORT_NOT_FOUND'),
            zod_1.z.literal('INVALID_PORT_TYPE'),
            zod_1.z.literal('NO_CURRENT_TRANSFER'),
            zod_1.z.literal('NO_CURRENT_BIN'),
            zod_1.z.literal('BIN_NOT_EMPTY'),
        ]),
        portId: base_1.portIdSchema,
        numberOfPartitions: zod_1.z.number().int().positive(),
    }),
});
exports.completePutawayFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('COMPLETE_PUTAWAY_FAILED'),
    payload: zod_1.z.object({
        reason: zod_1.z.union([
            zod_1.z.literal('NO_CURRENT_BIN'),
            zod_1.z.literal('NO_CURRENT_TRANSFER'),
            zod_1.z.literal('NO_AUTOSTORE'),
            zod_1.z.literal('PORT_NOT_FOUND'),
            zod_1.z.literal('INVALID_PORT_TYPE'),
            zod_1.z.literal('INVALID_PORT_STATUS'),
            zod_1.z.literal('CLOSE_BIN_FAILED'),
            zod_1.z.literal('CLOSE_PORT_FAILED'),
        ]),
        portId: base_1.portIdSchema,
    }),
});
exports.putawayPortClosedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PUTAWAY_PORT_CLOSED'),
    payload: zod_1.z.object({
        portId: zod_1.z.number(),
    }),
});
exports.closePutawayPortFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CLOSE_PUTAWAY_PORT_FAILED'),
    payload: zod_1.z.discriminatedUnion('outcome', [
        zod_1.z.object({ outcome: zod_1.z.literal('WAREHOUSE_NOT_FOUND') }),
        zod_1.z.object({ outcome: zod_1.z.literal('WAREHOUSE_WITH_AUTOSTORE_NOT_FOUND') }),
        zod_1.z.object({ outcome: zod_1.z.literal('PORT_NOT_FOUND'), portId: zod_1.z.number() }),
        zod_1.z.object({
            outcome: zod_1.z.literal('INVALID_PORT_TYPE'),
            portId: zod_1.z.number(),
            expectedPortType: zod_1.z.literal('PUTAWAY'),
            actualPortType: zod_1.z.enum(['UNCONFIGURED', 'PICK', 'PUTAWAY']),
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('INVALID_PORT_STATUS'),
            portId: zod_1.z.number(),
            currentPortStatus: zod_1.z.enum(['OPEN', 'CLOSED', 'AWAITING_BIN', 'READY']),
            expectedPortStatus: zod_1.z.literal('OPEN'),
        }),
        zod_1.z.object({
            outcome: zod_1.z.literal('AUTOSTORE_ERROR'),
            autostoreError: zod_1.z.string(),
        }),
    ]),
});
exports.binEmptinessCheckedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('BIN_EMPTINESS_CHECKED'),
    payload: zod_1.z.object({
        autostoreBinId: base_1.autostoreBinIdSchema,
        binId: zod_1.z.string().min(1),
        portId: base_1.portIdSchema,
        isEmpty: zod_1.z.boolean(),
    }),
});
exports.putawayBinRequestedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PUTAWAY_BIN_REQUESTED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        autostoreBinId: base_1.autostoreBinIdSchema,
        binId: zod_1.z.string().min(1),
    }),
});
exports.noMatchingBinsOutcome = zod_1.z.object({
    outcome: zod_1.z.literal('NO_MATCHING_BINS_EXIST'),
    autostoreError: zod_1.z.string(),
});
exports.noMatchingBinsReadyOutcome = zod_1.z.object({
    outcome: zod_1.z.literal('NO_MATCHING_BINS_READY'),
    autostoreError: zod_1.z.string(),
});
exports.unhandledAutostoreManagerOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('UNEXPECTED_AUTOSTORE_ERROR'),
    autostoreError: zod_1.z.string(),
});
exports.warehouseNotFoundOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('WAREHOUSE_NOT_FOUND'),
    warehouseId: zod_1.z.string(),
});
exports.warehouseWithNoAutostoreOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('WAREHOUSE_WITH_AUTOSTORE_NOT_FOUND'),
    warehouseId: zod_1.z.string(),
});
const noCurrentBinOutcome = zod_1.z.object({
    outcome: zod_1.z.literal('NO_CURRENT_BIN'),
    warehouseId: zod_1.z.string(),
    currentAutostoreBinId: zod_1.z.number().optional(),
});
exports.putawayBinRequestFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PUTAWAY_BIN_REQUEST_FAILED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        reason: zod_1.z.union([
            exports.noMatchingBinsOutcome,
            exports.noMatchingBinsReadyOutcome,
            exports.unhandledAutostoreManagerOutcomeSchema,
            exports.warehouseNotFoundOutcomeSchema,
            exports.warehouseWithNoAutostoreOutcomeSchema,
            noCurrentBinOutcome,
        ]),
    }),
});
exports.binEmptinessCheckFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CHECK_BIN_EMPTINESS_FAILED'),
    payload: zod_1.z.object({
        reason: zod_1.z.union([
            zod_1.z.literal('PORT_NOT_FOUND'),
            zod_1.z.literal('INVALID_PORT_TYPE'),
            zod_1.z.literal('NO_CURRENT_BIN'),
        ]),
        portId: base_1.portIdSchema,
        isEmpty: zod_1.z.boolean(),
    }),
});
exports.replenishmentModeSelectedSchema = base_1.portEventBaseSchema.extend({
    type: zod_1.z.literal('REPLENISHMENT_MODE_SELECTED'),
    portId: zod_1.z.number().int().positive(),
    payload: zod_1.z.object({
        warehouseId: zod_1.z.string().min(1),
        portId: base_1.portIdSchema,
        replenishmentMode: zod_1.z.enum(['STOCK_TRANSFER', 'RETURNS_RESTOCKING']),
    }),
});
exports.selectReplenishmentModeFailedSchema = base_1.portEventBaseSchema.extend({
    type: zod_1.z.literal('SELECT_REPLENISHMENT_MODE_FAILED'),
    portId: zod_1.z.number().int().positive(),
    payload: zod_1.z.object({
        warehouseId: zod_1.z.string().min(1),
        portId: base_1.portIdSchema,
        reason: zod_1.z.discriminatedUnion('outcome', [
            zod_1.z.object({ outcome: zod_1.z.literal('PORT_NOT_FOUND') }),
            zod_1.z.object({
                outcome: zod_1.z.literal('REPLENISHMENT_MODE_ALREADY_SELECTED'),
                selectedReplenishmentMode: zod_1.z.enum([
                    'STOCK_TRANSFER',
                    'RETURNS_RESTOCKING',
                ]),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('INVALID_PORT_STATUS'),
                expectedPortStatus: zod_1.z.union([
                    putawayStatusSchema.array(),
                    putawayStatusSchema,
                ]),
                currentPortStatus: putawayStatusSchema,
            }),
        ]),
    }),
});
exports.PutawayEvent = zod_1.z.union([
    exports.putawayPortOpenedSchema,
    PutawayPortOpenFailed,
    exports.putawayCompletedSchema,
    exports.putawayBinRequestedSchema,
    exports.putawayBinRequestFailedSchema,
    exports.binEmptinessCheckedSchema,
    exports.binEmptinessCheckFailedSchema,
    exports.putawayBinReturnedSchema,
    exports.returnPutawayBinFailedSchema,
    exports.putawayItemAddedSchema,
    addPutawayItemFailedSchema,
    exports.putawayItemRemovedSchema,
    removePutawayItemFailedSchema,
    exports.partitionsConfiguredSchema,
    exports.configurePartitionsFailedSchema,
    exports.completePutawayFailedSchema,
    exports.closePutawayPortFailedSchema,
    exports.putawayPortClosedSchema,
    exports.replenishmentModeSelectedSchema,
    exports.selectReplenishmentModeFailedSchema,
    exports.barcodeScannedAtPutawaySchema,
    exports.scanBarcodeAtPutawayFailedSchema,
    exports.barcodeScanUndoneAtPutawaySchema,
    exports.undoBarcodeScanAtPutawayFailedSchema,
]);
