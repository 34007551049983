import { Box, Divider, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { PortBinStateType, PutawayPort } from 'api-schema/lib/model';
import { Button } from '../../../../../components/admin/Button';
import { Container } from '../../../../../components/admin/Container';
import { TransferDetailsItem } from '../TransferDetailsItem';

type Props = {
  transfer: Exclude<PutawayPort['currentTransfer'], undefined>;
  onCompletePutaway: () => void;
  disabled: boolean;
  targetTransferBarcode?: string;
  currentBin?: PortBinStateType;
};

export const TransferDetailsView = ({
  transfer,
  onCompletePutaway,
  disabled,
  targetTransferBarcode,
  currentBin,
}: Props) => {
  const getQuantityPutawayFromBin = (retailUnitKey: string): number =>
    (currentBin?.partitions || [])
      .filter((partition) => partition.retailUnitKey === retailUnitKey)
      .reduce((sum, partition) => sum + (partition.quantityPutaway || 0), 0);

  return (
    <>
      <Container>
        <Box display="flex" justifyContent="space-between">
          <Typography>Retail unit</Typography>
          <Typography>QTY</Typography>
        </Box>
        <Divider />
        <>
          {transfer.items?.length > 0 ? (
            transfer.items.map((item) => {
              const isFirstItemWithThisBarcode =
                transfer.items.find(
                  (i) => i.retailUnitKey === item.retailUnitKey
                ) === item;

              let quantityPutaway = item.quantityPutaway || 0;
              if (isFirstItemWithThisBarcode) {
                quantityPutaway += getQuantityPutawayFromBin(
                  item.retailUnitKey
                );
              }

              return (
                <TransferDetailsItem
                  key={item.retailUnitKey}
                  name={item.retailUnit?.sku}
                  barcode={item.retailUnit?.barcode ?? ''}
                  quantity={item.quantity}
                  quantityPutaway={quantityPutaway}
                  isSelected={
                    targetTransferBarcode === item.retailUnit?.barcode
                  }
                />
              );
            })
          ) : (
            <>
              <Typography variant="body2">
                There are no items in this transfer.
              </Typography>
            </>
          )}
        </>
      </Container>
      <Button
        variant="heavy"
        fullWidth
        onClick={onCompletePutaway}
        icon={Check}
        alignIcon="left"
        disabled={disabled}
        data-testid="complete-putaway"
      >
        Complete putaway
      </Button>
    </>
  );
};
